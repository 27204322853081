import Register from '@/modules/admins/components/Register'
import ListUsers from '@/modules/admins/components/ListUsers'
import Categories from '@/modules/admins/components/Categories'
import Therapists from '@/modules/admins/components/Therapists'
import EditTherapists from '@/modules/admins/components/EditTherapists'
import EditCategory from '@/modules/admins/components/EditCategory'
import ListTherapistPdf from '@/modules/admins/components/ListTherapistPdf'
import MeasurementReminder from '@/modules/admins/components/MeasurementReminder'
import Impressum from '@/modules/admins/components/Impressum'
import MeasurementOverview from '@/modules/admins/components/MeasurementOverview'
import ReportZusatzfragen from '@/modules/admins/components/ReportZusatzfragen'



export default [
  {
    path: "/admins/register",
    name: 'Register',
    meta: { requiresAuth: true },
    component: Register
  },
  {
    path: "/admins/list_users",
    name: 'ListUsers',
    meta: { requiresAuth: true },
    component: ListUsers
  },
  {
    path: "/admins/categories",
    name: 'Categories',
    meta: { requiresAuth: true },
    component: Categories
  },
  {
    path: "/admins/therapists/:search?",
    name: 'Therapists',
    meta: { requiresAuth: true },
    component: Therapists
  },
  {
    path: "/admins/edit_therapists/:therapistId",
    name: 'EditTherapists',
    meta: { requiresAuth: true },
    component: EditTherapists
  },
  {
    path: "/admins/edit_category/:categoryId",
    name: 'EditCategory',
    meta: { requiresAuth: true },
    component: EditCategory
  },
  {
    path: "/admins/list_therapist_pdf",
    name: 'ListTherapistPdf',
    meta: { requiresAuth: true },
    component: ListTherapistPdf
  },
  {
    path: "/admins/measurement_reminder",
    name: 'MeasurementReminder',
    meta: { requiresAuth: false, layout: 'no-bars' },
    component: MeasurementReminder
  },
  {
    path: "/admins/impressum",
    name: 'Impressum',
    meta: { requiresAuth: true },
    component: Impressum
  },
  {
    path: "/admins/list_measurements",
    name: 'MeasurementOverview',
    meta: { requiresAuth: true },
    component: MeasurementOverview
  },
  {
    path: "/admins/report_zusatzfragen",
    name: 'ReportZusatzfragen',
    meta: { requiresAuth: true },
    component: ReportZusatzfragen
  },
]
