<template>
    <desktop v-if="!isMobile && !isFetching"/>
    <keep-alive>
    <mobile v-if="isMobile && !isFetching"/>
    </keep-alive>
</template>
<script>
import { defineComponent } from 'vue'
import desktop from './Measurement-desktop'
import mobile from './Measurement-mobile'
import { mapGetters } from "vuex";
export default defineComponent({
    components:{
        desktop,
        mobile
    },
    data() {
        return {
            isMobile: false,
            mql: null,
            isFetching: false
        }
    },
    mounted() {
        this.mql = window.matchMedia('(max-width: 700px)');
        this.isMobile = this.mql.matches
        this.mql.addEventListener('change', this.handleMqlChange);
        this.loadMeasurement();
    },
    unmounted(){
        this.mql.removeEventListener('change', this.handleMqlChange)
    },
    methods: {
        handleMqlChange(e){
            this.isMobile = e.matches
        },
        async loadMeasurement() {
        if (this.$route.params.measurementId === "new") {
            let fk_user = this.FK_USER;
            if(fk_user == null){
                fk_user = localStorage.getItem("id");
            }
            console.log("user ist " + fk_user)
            this.isFetching = true;
            await this.$store.dispatch("Measurements/CREATE", { fk_user: fk_user, show_therapy_questions_override: this.SHOW_THERAPY_QUESTIONS });
            this.isFetching = false;
            return;
        }
        if (this.$route.params.measurementId !== "new") {
            this.isFetching = true;
            await this.$store.dispatch("Measurements/LOAD", {
            measurement_id: this.$route.params.measurementId, show_therapy_questions_override: this.SHOW_THERAPY_QUESTIONS
            });
            this.isFetching = false;
        }
        },
    },
    computed: {
    ...mapGetters({
      FK_USER: "Measurements/FK_USER",
      SHOW_THERAPY_QUESTIONS: "User/SHOW_THERAPY_QUESTIONS"
    })
    },
    watch: {
    $route: "loadMeasurement"
    }
})
</script>
