const mutations = {


  SET_ERRORS: (state, payload) => {
    state.admin.errors = payload
  },
  SET_ERRORS2: (state, payload) => {
    state.admin.errors2 = payload
  },
  SET_NEW_USER_FEEDBACK: (state, payload) => {
    let property_errors = payload.user ? payload.user.error : []
    state.admin.last_name_feedback = property_errors.last_name ? property_errors.last_name : ""
    state.admin.first_name_feedback = property_errors.first_name ? property_errors.first_name : ""
    state.admin.user_name_feedback = property_errors.user_name ? property_errors.user_name : ""
    state.admin.study_number_feedback = property_errors.study_number ? property_errors.study_number : ""
    state.admin.title = property_errors.title ? property_errors.title : ""
    state.admin.email_feedback = property_errors.email ? property_errors.email : ""
    state.admin.measurement0_feedback = property_errors.measurement0 ? property_errors.measurement0 : ""
    state.admin.measurement1_feedback = property_errors.measurement1 ? property_errors.measurement1 : ""
    state.admin.measurement2_feedback = property_errors.measurement2 ? property_errors.measurement2 : ""
    state.admin.measurement3_feedback = property_errors.measurement3 ? property_errors.measurement3 : ""
    state.admin.measurement4_feedback = property_errors.measurement4 ? property_errors.measurement4 : ""
    state.admin.measurement5_feedback = property_errors.measurement5 ? property_errors.measurement5 : ""
    state.admin.measurement6_feedback = property_errors.measurement6 ? property_errors.measurement6 : ""
  },
  SET_NEW_USER_ID: (state, payload) => {
    state.admin.new_user_id = payload.id
  },
  SET_NEW_USER_SUCCESS: (state, payload) => {
    state.admin.new_user_success = payload
  },
  SET_PASSWORD1_FEEDBACK: (state, payload) => {
    state.admin.password1_feedback = payload
  },
  SET_PASSWORD2_FEEDBACK: (state, payload) => {
    state.admin.password2_feedback = payload
  },
  SET_EMAIL_FEEDBACK: (state, payload) => {
    state.admin.email_feedback = payload
  },
  SET_USERS_LIST: (state, payload) => {
    state.admin.users_list = payload
  },
  SET_PATIENTS_LIST: (state, payload) => {
    state.admin.patients_list = payload
  },
  SET_CLINICS_LIST: (state, payload) => {
    state.admin.clinics_list = payload
  },
  SET_MEASUREMENT_COUNT: (state, payload) => {
    state.admin.measurement_count = payload.count
  },
  SET_FK_CLINIC: (state, payload) => {
    state.admin.fk_clinic = payload
  },
  CLEAR_REGISTER_KEY: (state) => {
    state.admin.register_key = state.admin.register_key + 1
  },
  SET_CATEGORIES_LIST: (state, payload) => {
    state.admin.categories = payload
  },
  SET_THERAPISTS_LIST: (state, payload) => {
    state.admin.therapists = payload
  },
  SET_CATEGORY: (state,payload) => {
    state.admin.category = payload
  },
  SET_SCORES_LIST: (state, payload) => {
    state.admin.scores = payload
  },
  CATEGORY_SET_SCORE: (state, payload) => {
    state.admin.category.score = payload
  },
  SET_CHANGE_PASSWORD_FEEDBACK: (state, payload) => {
    let property_errors = payload.user ? payload.user.error : []
    state.admin.password1_feedback = property_errors.password1 ? property_errors.password1 : ""
    state.admin.password2_feedback = property_errors.password2 ? property_errors.password2 : ""
  },
  SET_CHANGE_PASSWORD_SUCCESS: (state, payload) => {
    state.admin.show_success = payload
  },
  SET_EDIT_SUCCESS: (state, payload) => {
    state.admin.show_success = payload
  },
  SET_EDIT_ERROR: (state, payload) => {
    state.admin.edit_error = payload
    if(payload.length > 0){
      state.admin.email_feedback = payload[0]['email'] ? payload[0]['email'] : ''
      state.admin.measurement1_feedback = payload[0]['measurement1'] ? payload[0]['measurement1'] : ''
      state.admin.measurement2_feedback = payload[0]['measurement2'] ? payload[0]['measurement2'] : ''
      state.admin.measurement3_feedback = payload[0]['measurement3'] ? payload[0]['measurement3'] : ''
      state.admin.measurement4_feedback = payload[0]['measurement4'] ? payload[0]['measurement4'] : ''
      state.admin.measurement5_feedback = payload[0]['measurement5'] ? payload[0]['measurement5'] : ''
      state.admin.measurement6_feedback = payload[0]['measurement6'] ? payload[0]['measurement6'] : ''
      state.admin.study_number_feedback = payload[0]['study_number'] ? payload[0]['study_number'] : ''
    }else{
      state.admin.email_feedback = ""
      state.admin.measurement1_feedback = ""
      state.admin.measurement2_feedback = ""
      state.admin.measurement3_feedback = ""
      state.admin.measurement4_feedback = ""
      state.admin.measurement5_feedback = ""
      state.admin.measurement6_feedback = ""
      state.admin.study_number_feedback = ""
    }
  },
  SET_REGIONS: (state, payload) => {
    state.admin.regions = payload
  },
  SET_REGION: (state, payload) => {
    state.admin.region = payload
  },
  SET_SEX: (state, payload) => {
    state.admin.sex = payload
  },
  SET_SUCCESS_DIALOG: (state, payload) => {
    state.admin.success_dialog = payload
  },
  SET_NOTIFICATION: (state, payload) => {
    state.admin.notification = payload
  }
}

export default mutations