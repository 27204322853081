<template>
          <div class="embed-responsive card infocard_video">
       <video-player
    :src="this.API_URL + '/resources/videos/Aerzte.mp4'"
    :poster="this.API_URL + '/resources/videos/video_bkgrnd.jpg'"
    controls
    :loop="true"
    :volume="0.6"
    :height="520"
    :responsive="true"
  />
</div>
<div class="card infocard2">
  <h5 class="card-title">Über den folgenden Button können Sie sich eine Anleitung der Anwendung herunterladen</h5>
  <button type="button" class="btn btn-primary" @click="this.downloadTutorialPdf()"> Anleitung herunterladen </button>
</div>
    <div class="card bigcard">
      <div class="card-body">
        <h5 class="card-title">Messungen</h5>
        <p v-if="NEW_COUNT > 0" class="text-danger">Es gibt {{ NEW_COUNT }} neue Messungen </p> <a @click="showNewMeasurementModal" href="#">Hier klicken</a>
        <p v-if="NEW_COUNT == 0">Es gibt keine neuen Messungen</p>
      </div>
      <div class="card-body">
        <h5 class="card-title">Patienten</h5>
        <hr />
        <input
          type="text"
          class="form-control"
          placeholder="Suchbegriff"
          aria-label="Suchbegriff"
          aria-describedby="search"
          v-model="search_key"
          @keyup.enter="search(1)"
          ref="search_field"
        />
        <button
          class="btn btn-primary"
          type="button"
          id="button-addon2"
          @click="search(1)"
        >
          <i class="bi-button bi-search"></i>
        </button>
        <table class="table table-striped table-responsive">
          <thead>
            <tr>
              <th scope="col">Nachname</th>
              <th scope="col">Vorname</th>
              <th scope="col">Benutzername</th>
              <th scope="col">Therapieabschnitt</th>
              <th scope="col">Anzahl Messungen</th>
              <th scope="col">Einsehen</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="patient in PATIENT_LIST.users" :key="patient.user_name" :class="patient.dropped_out_at != null ? 'table-danger' : 'table'">
              <td>{{ patient.last_name }}</td>
              <td>{{ patient.first_name }}</td>
              <td>{{ patient.user_name }}</td>
              <td>{{ JSON.parse(patient.therapy_arm).str }}</td>
              <td>{{ patient.nr_of_measurements }}</td>
            <td>
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="showMeasurementListModal(patient.id)"
                >
                  >
                </button>
              </td>
            </tr>
          </tbody>
          <caption v-if="PATIENT_LIST.pagination">
            {{
              PATIENT_LIST.pagination.count
            }}
            Patienten gefunden.
          </caption>
        </table>
      </div>
      <div class="row">
        <nav v-if="PATIENT_LIST.pagination" aria-label="Seitennavigation">
          <ul class="pagination justify-content-center">
            <li
              :class="
                PATIENT_LIST.pagination.prevPage
                  ? 'page-item'
                  : 'page-item disabled'
              "
            >
              <a
                class="page-link"
                href="#"
                aria-label="Zurück"
                @click="search(1)"
              >
                <i class="bi-chevron-double-left"></i>
              </a>
            </li>
            <li
              :class="
                PATIENT_LIST.pagination.prevPage
                  ? 'page-item'
                  : 'page-item disabled'
              "
            >
              <a
                class="page-link"
                href="#"
                aria-label="Zurück"
                @click="search(PATIENT_LIST.pagination.page - 1)"
              >
                <i class="bi-chevron-compact-left"></i>
              </a>
            </li>
            <li
              :class="
                i === PATIENT_LIST.pagination.requestedPage
                  ? 'page-item active'
                  : 'page-item'
              "
              v-for="i in PATIENT_LIST.pagination.pageCount"
              :key="i"
            >
              <a class="page-link" @click="search(i)">{{ i }}</a>
            </li>
            <li
              :class="
                PATIENT_LIST.pagination.nextPage
                  ? 'page-item'
                  : 'page-item disabled'
              "
            >
              <a
                class="page-link"
                href="#"
                aria-label="Nächste"
                @click="search(PATIENT_LIST.pagination.page + 1)"
              >
                <i class="bi-chevron-compact-right"></i>
              </a>
            </li>
            <li
              :class="
                PATIENT_LIST.pagination.nextPage
                  ? 'page-item'
                  : 'page-item disabled'
              "
            >
              <a
                class="page-link"
                href="#"
                aria-label="Nächste"
                @click="search(PATIENT_LIST.pagination.pageCount)"
              >
                <i class="bi-chevron-double-right"></i>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  <measurement-list-modal-doctor
    ref="measurementListModal"
    :show="MEASUREMENTS_LIST_MODAL_VISIBLE"
    @modalClosed="measurementListModalClosed"
    :patientId="WELCOME_PAGE_SELECTED_PATIENT"
    v-if="WELCOME_PAGE_SELECTED_PATIENT"
  />
  <not-seen-modal-doctor
    ref="notSeenModal"
    :show="NEW_MEASUREMENT_MODAL_VISIBLE"
    @modalClosed="newMeasurementModalClosed"
  />
</template>
<script>
import { defineComponent, ref, onMounted} from "vue";
import { mapGetters, useStore } from "vuex";
import measurementListModalDoctor from "@/modules/measurements/components/MeasurementListModalDoctor";
import notSeenModalDoctor from "@/modules/measurements/components/NotSeenModalDoctor";
import { VideoPlayer } from '@videojs-player/vue'

export default defineComponent({
  components: {
    measurementListModalDoctor,
    notSeenModalDoctor,
    VideoPlayer
  },
  computed: {
    ...mapGetters({
      PATIENT_LIST: "User/PATIENTS_LIST",
      MEASUREMENT_COUNT: "Admin/MEASUREMENT_COUNT",
      MEASUREMENTS_LIST_MODAL_VISIBLE: "User/MEASUREMENTS_LIST_MODAL_VISIBLE",
      NEW_MEASUREMENT_MODAL_VISIBLE: "User/NEW_MEASUREMENT_MODAL_VISIBLE",
      WELCOME_PAGE_SELECTED_PATIENT: "User/WELCOME_PAGE_SELECTED_PATIENT",
      NEW_COUNT: "Measurements/NEW_COUNT"
    }),
  },
  setup() {
    const store = useStore();
    const search_field = ref(null);
    const current_page = ref(1);
    const search_key = ref("");
    const per_page = ref(15);
    const API_URL =  (process.env.NODE_ENV === 'development') ? 'http://localhost:8765' : 'https://lepalumo.tumorzentrum-regensburg.de/lepalumo';

    const search = (cp = 1) => {
      current_page.value = cp;
      store.dispatch("User/LIST_PATIENTS_FOR_CLINIC", {
        search_key: search_key.value,
        per_page: per_page.value,
        current_page: current_page.value,
        fk_clinic: localStorage.getItem("id"),
      });
      if (search_field.value !== null) {
        search_field.value.focus();
      }
    };

    const downloadTutorialPdf = () => {
            var fileName = API_URL + '/resources/pdf/lepalumo_dok_arzt.pdf'
            const link = document.createElement("a");
      link.href = fileName;
      link.download = "Lepalumo_Anleitung";
      link.setAttribute("download", "Lepalumo_Anleitung");
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
          };



    const showMeasurementListModal = (patientId) => {
      store.commit("User/SET_MEASUREMENTS_LIST_MODAL_VISIBLE", {
        patientId: patientId,
        visible: true,
      });
    };

    const showNewMeasurementModal = () => {
      store.commit("User/SET_NEW_MEASUREMENTS_MODAL_VISIBLE", {
        visible: true,
      });
    };

    const newMeasurementModalClosed = () => {
      store.commit("User/SET_NEW_MEASUREMENTS_MODAL_VISIBLE", false);
    };

    const measurementListModalClosed = () => {
      store.commit("User/SET_MEASUREMENTS_LIST_MODAL_VISIBLE", false);
    };

    onMounted(() => {
            store.dispatch("Measurements/GET_NEW_COUNT")
            store.dispatch("User/LIST_PATIENTS_FOR_CLINIC", {
        search_key: search_key.value,
        per_page: per_page.value,
        current_page: current_page.value,
        fk_clinic: localStorage.getItem("id"),
      });
    });
    return {
      search_field,
      current_page,
      search_key,
      per_page,
      search,
      showMeasurementListModal,
      measurementListModalClosed,
      showNewMeasurementModal,
      newMeasurementModalClosed,
      API_URL,
      downloadTutorialPdf
    };
  },
});
</script>