<template>
<div class="container p-5">
      <div class="card bigcard col">
        <div class="card-body">
          <h5 class="card-title">Messungen</h5>
          <div class="card">
            <div class="card-body">
              <table class="table table-striped table-responsive">
                <thead>
                  <tr>
                    <th scope="col">Benutzername</th>
                    <th scope="col">Studiennummer</th>
                    <th scope="col">Messung 0</th>
                    <th scope="col">Messung 1</th>
                    <th scope="col">Messung 2</th>
                    <th scope="col">Messung 3</th>
                    <th scope="col">Messung 4</th>
                    <th scope="col">Messung 5</th>
                    <th scope="col">Messung 6</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="measurement in MEASUREMENTS_OVERVIEW"
                    :key="measurement.id"
                    :class="measurement.dropped_out_at != null ? 'table-danger' : 'table'"
                  >
                    <td>
                        {{measurement.name}}
                    </td>
                    <td>
                        {{measurement.study_number}}
                    </td>
                    <td>
                      <i class='bi bi-check-lg' v-if="measurement.measurement0 == 2" style="color: green" data-toggle="tooltip" data-placement="bottom" title="Messung durchgeführt"></i>
                      <i class='bi bi-x-lg' v-else-if="measurement.measurement0 == 3" style="color: red" data-placement="bottom" title="Messung verpasst"></i>
                      <i class='bi bi-circle' v-else-if="measurement.measurement0 == 1" style="color: #ffc000" data-placement="bottom" title="Messung möglich"></i>
                      <i class='bi bi-cursor-text' v-else-if="measurement.measurement0 == 0" style="color: red" data-placement="bottom" title="Kein Zeitraum eingestellt"></i>
                      <i class='bi bi-hourglass' v-else style="color: blue" data-toggle="tooltip" data-placement="bottom" :title="measurement.measurement0"></i>
                    </td>
                    <td>
                      <i class='bi bi-check-lg' v-if="measurement.measurement1 == 2" style="color: green" data-toggle="tooltip" data-placement="bottom" title="Messung durchgeführt"></i>
                      <i class='bi bi-x-lg' v-else-if="measurement.measurement1 == 3" style="color: red" data-placement="bottom" title="Messung verpasst"></i>
                      <i class='bi bi-circle' v-else-if="measurement.measurement1 == 1" style="color: #ffc000" data-placement="bottom" title="Messung möglich"></i>
                      <i class='bi bi-cursor-text' v-else-if="measurement.measurement1 == 0" style="color: red" data-placement="bottom" title="Kein Zeitraum eingestellt"></i>
                      <i class='bi bi-hourglass' v-else style="color: blue" data-toggle="tooltip" data-placement="bottom" :title="measurement.measurement1"></i>
                    </td>
                    <td>
                      <i class='bi bi-check-lg' v-if="measurement.measurement2 == 2" style="color: green" data-toggle="tooltip" data-placement="bottom" title="Messung durchgeführt"></i>
                      <i class='bi bi-x-lg' v-else-if="measurement.measurement2 == 3" style="color: red" data-placement="bottom" title="Messung verpasst"></i>
                      <i class='bi bi-circle' v-else-if="measurement.measurement2 == 1" style="color: #ffc000" data-placement="bottom" title="Messung möglich"></i>
                      <i class='bi bi-cursor-text' v-else-if="measurement.measurement2 == 0" style="color: red" data-placement="bottom" title="Kein Zeitraum eingestellt"></i>
                      <i class='bi bi-hourglass' v-else style="color: blue" data-toggle="tooltip" data-placement="bottom" :title="measurement.measurement2"></i>
                    </td>
                    <td>
                      <i class='bi bi-check-lg' v-if="measurement.measurement3 == 2" style="color: green" data-toggle="tooltip" data-placement="bottom" title="Messung durchgeführt"></i>
                      <i class='bi bi-x-lg' v-else-if="measurement.measurement3 == 3" style="color: red" data-placement="bottom" title="Messung verpasst"></i>
                      <i class='bi bi-circle' v-else-if="measurement.measurement3 == 1" style="color: #ffc000" data-placement="bottom" title="Messung möglich"></i>
                      <i class='bi bi-cursor-text' v-else-if="measurement.measurement3 == 0" style="color: red" data-placement="bottom" title="Kein Zeitraum eingestellt"></i>
                      <i class='bi bi-hourglass' v-else style="color: blue" data-toggle="tooltip" data-placement="bottom" :title="measurement.measurement3"></i>
                    </td>
                    <td>
                      <i class='bi bi-check-lg' v-if="measurement.measurement4 == 2" style="color: green" data-toggle="tooltip" data-placement="bottom" title="Messung durchgeführt"></i>
                      <i class='bi bi-x-lg' v-else-if="measurement.measurement4 == 3" style="color: red" data-placement="bottom" title="Messung verpasst"></i>
                      <i class='bi bi-circle' v-else-if="measurement.measurement4 == 1" style="color: #ffc000" data-placement="bottom" title="Messung möglich"></i>
                      <i class='bi bi-cursor-text' v-else-if="measurement.measurement4 == 0" style="color: red" data-placement="bottom" title="Kein Zeitraum eingestellt"></i>
                      <i class='bi bi-hourglass' v-else style="color: blue" data-toggle="tooltip" data-placement="bottom" :title="measurement.measurement4"></i>
                    </td>
                    <td>
                      <i class='bi bi-check-lg' v-if="measurement.measurement5 == 2" style="color: green" data-toggle="tooltip" data-placement="bottom" title="Messung durchgeführt"></i>
                      <i class='bi bi-x-lg' v-else-if="measurement.measurement5 == 3" style="color: red" data-placement="bottom" title="Messung verpasst"></i>
                      <i class='bi bi-circle' v-else-if="measurement.measurement5 == 1" style="color: #ffc000" data-placement="bottom" title="Messung möglich"></i>
                      <i class='bi bi-cursor-text' v-else-if="measurement.measurement5 == 0" style="color: red" data-placement="bottom" title="Kein Zeitraum eingestellt"></i>
                      <i class='bi bi-hourglass' v-else style="color: blue" data-toggle="tooltip" data-placement="bottom" :title="measurement.measurement5"></i>
                    </td>
                    <td>
                      <i class='bi bi-check-lg' v-if="measurement.measurement6 == 2" style="color: green" data-toggle="tooltip" data-placement="bottom" title="Messung durchgeführt"></i>
                      <i class='bi bi-x-lg' v-else-if="measurement.measurement6 == 3" style="color: red" data-placement="bottom" title="Messung verpasst"></i>
                      <i class='bi bi-circle' v-else-if="measurement.measurement6 == 1" style="color: #ffc000" data-placement="bottom" title="Messung möglich"></i>
                      <i class='bi bi-cursor-text' v-else-if="measurement.measurement6 == 0" style="color: red" data-placement="bottom" title="Kein Zeitraum eingestellt"></i>
                      <i class='bi bi-hourglass' v-else style="color: blue" data-toggle="tooltip" data-placement="bottom" :title="measurement.measurement6"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
              <button class="btn btn-primary" @click="downloadWithAxios">Tabelle Exportieren</button>
              <hr>
              <hr>
              <div>
                  <h4>Korrektur von doppelten und fehlenden Messnummern</h4>
                      <h5>User mit doppelten Messnummern</h5>
                      <table class="table table-striped table-responsive">
                        <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Benutzername</th>
                    <th scope="col">Studiennummer</th>
                    <th scope="col">Vorname</th>
                    <th scope="col">Nachname</th>
                    <th scope="col">Messungen</th>
                  </tr>
                </thead>
                        <tr
                    v-for="duplicate_measurement_number in DUPLICATE_MEASUREMENT_NUMBERS"
                    :key="duplicate_measurement_number.id"
                  >
                  <td>{{ duplicate_measurement_number.id }}</td>
                  <td>{{ duplicate_measurement_number.user_name }}</td>
                  <td>{{ duplicate_measurement_number.study_number }}</td>
                  <td>{{ duplicate_measurement_number.first_name }}</td>
                  <td>{{ duplicate_measurement_number.last_name }}</td>
                  <td>
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="showMeasurementListModal(duplicate_measurement_number.id, duplicate_measurement_number.show_therapy_questions)"
                >
                  >
                </button>
              </td>
                  </tr>
                      </table>
                      <h5>User mit fehlenden Messnummern</h5>
                      <table class="table table-striped table-responsive">
                        <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Benutzername</th>
                    <th scope="col">Studiennummer</th>
                    <th scope="col">Vorname</th>
                    <th scope="col">Nachname</th>
                    <th scope="col">Messungen</th>
                  </tr>
                </thead>
                        <tr
                    v-for="missing_measurement_number in MISSING_MEASUREMENT_NUMBERS"
                    :key="missing_measurement_number.id"
                  >
                  <td>{{ missing_measurement_number.id }} </td>
                  <td>{{ missing_measurement_number.user_name }}</td>
                  <td>{{ missing_measurement_number.study_number }}</td>
                  <td>{{ missing_measurement_number.first_name }}</td>
                  <td>{{ missing_measurement_number.last_name }}</td>
                  <td>
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="showMeasurementListModal(missing_measurement_number.id, missing_measurement_number.show_therapy_questions)"
                >
                  >
                </button>
              </td>
                  </tr>
                      </table>
              </div>
        </div>
    </div>
    </div>
</div>
</div>
<measurement-list-modal-admin
    ref="measurementListModal"
    :show="MEASUREMENTS_LIST_MODAL_VISIBLE && !isFetching"
    @modalClosed="measurementListModalClosed"
    :patientId="WELCOME_PAGE_SELECTED_PATIENT"
    v-if="WELCOME_PAGE_SELECTED_PATIENT"
  />
</template>
<script>
import { defineComponent } from 'vue'
import { mapGetters } from "vuex";
import axios from '@/core/api'
import measurementListModalAdmin from "@/modules/measurements/components/MeasurementListModalAdmin";

export default defineComponent({
  data(){
    return {
            isFetching: false
        }
  },
  computed: {
    ...mapGetters({
      MEASUREMENTS_OVERVIEW: "Measurements/MEASUREMENTS_OVERVIEW",
      DUPLICATE_MEASUREMENT_NUMBERS: "Measurements/DUPLICATE_MEASUREMENT_NUMBERS",
      MISSING_MEASUREMENT_NUMBERS: "Measurements/MISSING_MEASUREMENT_NUMBERS",
      MEASUREMENTS_LIST_MODAL_VISIBLE: "User/MEASUREMENTS_LIST_MODAL_VISIBLE",
      WELCOME_PAGE_SELECTED_PATIENT: "User/WELCOME_PAGE_SELECTED_PATIENT",
    }),
  },
  components: {
    measurementListModalAdmin,
  },
  mounted() {
    this.$store.dispatch("Measurements/LOAD_MEASUREMENTS_OVERVIEW");
  },
  methods: {
    downloadWithAxios(){
      var fileName = "measurements.xlsx";
      axios({
        method: "get",
        url: "/measurement_overview_excel_export",
        responseType: "arraybuffer",
      }).then((response) => {
        this.forceDownload(response, fileName);
      });
    },
    forceDownload(response, fileName){
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/vnd.ms-excel" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
    },
    showMeasurementListModal(patientId, show_therapy_questions) {
      console.log(show_therapy_questions)
      //store.commit('User/SET_WELCOME_PAGE_SELECTED_PATIENT', patientId)
      this.isFetching = true;
      this.$store.commit("User/SET_SHOW_THERAPY_QUESTIONS", true)
      this.$store.commit("User/SET_MEASUREMENTS_LIST_MODAL_VISIBLE", {
        patientId: patientId,
        visible: true,
      });
      this.isFetching = false;    
    },
    measurementListModalClosed(){
      this.$store.commit("User/SET_MEASUREMENTS_LIST_MODAL_VISIBLE", false);
    }

  }
})
</script>
