<template>
    <div class="container p-5">
        <error-list
            :errors="ERRORS"
        />
        <error-list
            :errors="ERRORS2"
            variant="warning"
            title="Hinweis"
        />
        <div class="card col bigcard">
            <div class="card-body">
                <h5 class="card-title">Patienten</h5>
                <hr/>
                <input 
                    type="text"
                    class="form-control"
                    placeholder="Suchbegriff"
                    aria-label="Suchbegriff"
                    aria-describedby="search"
                    v-model="search_key"
                    @keyup.enter="search(1)"
                    ref="search_field"
                >
                <button 
                    class="btn btn-primary"
                    type="button"
                    id="button-addon2"
                    @click="search(1)"
                >
                    <i class="bi-button bi-search"></i>
                </button>  
                <div class="dropdown">
  <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    Filter nach Rolle
  </button>
  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
    <a class="dropdown-item" href="#" @click="allRoles">Alle Benutzer</a>
    <a class="dropdown-item" href="#" @click="rolePatient">Patienten</a>
    <a class="dropdown-item" href="#" @click="roleDoctor">Ärzte</a>
    <a class="dropdown-item" href="#" @click="roleAdmin">Vertrauensstelle</a>
  </div>
</div>
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">Nachname</th>
                            <th scope="col">Vorname</th>
                            <th scope="col">Benutzername</th>
                            <th scope="col">Studiennummer</th>
                            <th scope="col">Benutzerstatus</th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                        </tr>
                    </thead>    
                    <tbody>
                        <tr v-for="user in USERS_LIST.users" :key="user.user_name" :class="user.dropped_out_at != null ? 'table-danger' : 'table'">
                            <td>{{user.last_name}}</td>
                            <td>{{user.first_name}}</td>
                            <td>{{user.user_name}}</td>
                            <td>{{user.study_number}}</td>
                            <td>{{JSON.parse(user.user_type).str}}</td>
                            <td>
                                <button type="button" class="btn btn-primary" @click="showEditUserModal(user)">Benutzer bearbeiten  </button>
                            </td>
                            <td>
                                <button 
                                    type="button" 
                                    class="btn btn-primary" 
                                    @click="showListClinicPatientsModal(user)" 
                                    v-if="JSON.parse(user.user_type).enum == 1"> Patienten Verwalten </button>
                                <button 
                                    type="button" 
                                    class="btn btn-primary" 
                                    @click="showListPatientClinicsModal(user)" 
                                    v-if="JSON.parse(user.user_type).enum == 0"> Klinik zuordnen </button>
                            </td>
                            <td>
                                       <button v-if="!user.hasSetPassword"
                                    type="button" 
                                    class="btn btn-primary" 
                                    @click="sendEmail(user)" 
                                    > Anmeldelink verschicken 
                                    </button>
                                               <p v-if="user.hasSetPassword">
                                    Anmeldung abgeschlossen
                                    </p>
                            </td>
                            <td>
                                       <button 
                                    type="button" 
                                    class="btn btn-primary" 
                                    @click="showSetPasswordModal(user)" 
                                    > Passwort setzen </button>
                            </td>
                            <td>
                                <div v-if="JSON.parse(user.user_type).enum == 0">
                                       <button v-if="!user.clinician_notification_sent"
                                    type="button" 
                                    class="btn btn-primary" 
                                    @click="notifyClinician(user)" 
                                    > Arzt benachrichtigen
                                    </button>
                                               <p v-if="user.clinician_notification_sent">
                                    Arzt benachrichtigt am {{ 
                        new Date(
                            user.clinician_notification_sent
                        ).toLocaleDateString("de-DE", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                        }) }}
                                    </p>
                                </div>
                            </td>
                            <td>
                                       <button 
                                    type="button" 
                                    class="btn btn-danger" 
                                    @click="showDeleteUserModal(user)" 
                                    > Benutzer löschen </button>
                            </td>
                        </tr>
                    </tbody>
                    <caption v-if="USERS_LIST.pagination">{{USERS_LIST.pagination.count}} Patienten gefunden. </caption>
                </table>
            </div>
            <div class="row">
                <nav v-if="USERS_LIST.pagination" aria-label="Seitennavigation">
                    <ul class="pagination justify-content-center">
                        <li :class="USERS_LIST.pagination.prevPage ? 'page-item' : 'page-item disabled'">
                            <a class="page-link" href="#" aria-label="Zurück" @click="search(1)">
                                <i class="bi-chevron-double-left"></i>
                            </a>
                        </li>
                        <li :class="USERS_LIST.pagination.prevPage ?  'page-item' : 'page-item disabled'">
                            <a class="page-link" href="#" aria-label="Zurück" @click="search(USERS_LIST.pagination.page-1)">
                                <i class="bi-chevron-compact-left"></i>
                            </a>
                        </li>
                        <li :class="i === USERS_LIST.pagination.requestedPage ? 'page-item active' : 'page-item'" v-for="i in USERS_LIST.pagination.pageCount" :key="i">
                            <a class="page-link" @click="search(i)">{{i}}</a>
                        </li>
                        <li :class="USERS_LIST.pagination.nextPage ? 'page-item' : 'page-item disabled'">
                            <a class="page-link" href="#" aria-label="Nächste" @click="search(USERS_LIST.pagination.page + 1)">
                                <i class="bi-chevron-compact-right"></i>
                            </a>
                        </li>
                        <li :class="USERS_LIST.pagination.nextPage ? 'page-item' : 'page-item disabled'">
                            <a class="page-link" href="#" aria-label="Nächste" @click="search(USERS_LIST.pagination.pageCount)">
                                <i class="bi-chevron-double-right"></i>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
       <edit-user-modal
            ref="editUserModal"
            :show="editUserModalVisible"
            :user="selected_user"
            @modalClosed="editUserModalClosed"
            v-if="editUserModalVisible"/>
        <list-patients-for-clinic-modal
            ref="listPatientsForClinicModal"
            :show="clincListPatientsModalVisible"
            :clinic="selected_user"
            @modalClosed="listClinicPatientsModalClosed"
            v-if="clincListPatientsModalVisible"
        />
        <list-clinics-for-patient-modal
            ref="listClinicsForPatientModal"
            :show="patientListClinicsModalVisible"
            :patient="selected_user"
            @modalClosed="listPatientClinicsModalClosed"
            @fkClinicChanged="fkClinicChanged"
            v-if="patientListClinicsModalVisible"
        />
        <set-password-modal
            ref="setPasswordModal"
            :show="setPasswordModalVisible"
            :user="selected_user"
            @modalClosed="setPasswordModalClosed"
            v-if="setPasswordModalVisible"
        />
        <notification-modal
        ref="notificationModal"
            :show="notificationModalVisible"
            @modalClosed="notificationModalClosed"
            :notification="NOTIFICATION"
            v-if="notificationModalVisible && NOTIFICATION != ''">

        </notification-modal>
        <delete-user-modal
        ref="deleteUserModal"
            :show="deleteUserModalVisible"
            :user="selected_user"
            @modalClosed="deleteUserModalClosed"
            @deletedUser="deleteUserModalClosed"
            v-if="deleteUserModalVisible">

        </delete-user-modal>
    </div>
</template>
<script>
import { defineComponent, onMounted } from 'vue'
import { mapGetters, useStore } from 'vuex'
import { ref } from 'vue'

import ErrorList from '@/core/components/ErrorList'
import EditUserModal from '@/modules/admins/components/EditUserModal'
import ListPatientsForClinicModal from '@/modules/admins/components/ListPatientsForClinicModal'
import ListClinicsForPatientModal from '@/modules/admins/components/ListClinicsForPatientModal'
import SetPasswordModal from '@/modules/admins/components/SetPasswordModal'
import NotificationModal from '@/modules/admins/components/NotificationModal'
import DeleteUserModal from '@/modules/admins/components/DeleteUserModal'

export default defineComponent({
    setup() {
        const store = useStore()
        const search_field = ref(null);
        const current_page = ref(1);
        const editUserModalVisible = ref(false);
        const deleteUserModalVisible = ref(false);
        const clincListPatientsModalVisible = ref(false);
        const patientListClinicsModalVisible = ref(false);
        const notificationModalVisible = ref(false);
        const setPasswordModalVisible = ref(false);
        const selected_user = ref(null);
        const search_key = ref('');
        const per_page = ref(15);
        const listPatientsForClinicModal = ref(null);
        const listClinicsForPatientModal = ref(null);
        const role = ref(99);

        const showEditUserModal = (user) => {
            store.commit("Admin/SET_EDIT_ERROR", [])
            store.commit("Admin/SET_EDIT_SUCCESS", false)
            selected_user.value = user
            editUserModalVisible.value = true
        }

        const showDeleteUserModal = (user) => {
            store.commit("Admin/SET_EDIT_ERROR", [])
            store.commit("Admin/SET_EDIT_SUCCESS", false)
            selected_user.value = user
            deleteUserModalVisible.value = true
        }
        

        const load = () => {
            store.dispatch("Admin/LIST_USERS", {
                search_key: search_key.value,
                per_page: per_page.value,
                current_page: current_page.value,
                role: role.value
            })
        }

        const search = (cp = 1) => {
            current_page.value = cp
            store.dispatch('Admin/LIST_USERS', {
                search_key: search_key.value,
                per_page: per_page.value,
                current_page: current_page.value,
                role: role.value
            })
            if(search_field.value !== null){
                search_field.value.focus()
            }
        }

        const editUserModalClosed = () => {
            editUserModalVisible.value = false
            load()
        }

        const showListClinicPatientsModal = (user) => {
            selected_user.value = user
            clincListPatientsModalVisible.value = true
        }

        const showListPatientClinicsModal = (user) => {
            selected_user.value = user
            patientListClinicsModalVisible.value = true
        }

        const showNotificationModal = () => {
            notificationModalVisible.value = true
        }

        const showSetPasswordModal = (user) => {
            selected_user.value = user
            setPasswordModalVisible.value = true
        }

        const setPasswordModalClosed = () => {
            setPasswordModalVisible.value = false
            load()
        }

        const deleteUserModalClosed = () => {
            deleteUserModalVisible.value = false
            load()
        }

        const notificationModalClosed = () => {
            notificationModalVisible.value = false
            store.commit("Admin/SET_NOTIFICATION", "")
            load()
        }


        const listClinicPatientsModalClosed = () => {
            clincListPatientsModalVisible.value = false
            load()
        }


        const listPatientClinicsModalClosed = () => {
            patientListClinicsModalVisible.value = false
            load()
        }

        const fkClinicChanged = (value) => {
            store.dispatch("Admin/SET_FK_CLINIC", value)
        }

        const sendEmail = (user) =>{
            store.dispatch("Admin/SEND_PASSWORD_LINK", {id: user.id})
            showNotificationModal()
        }

        const notifyClinician = (user) =>{
            store.dispatch("Admin/NOTIFY_CLINICIAN", {fk_clinic: user.fk_clinic, patient_id: user.id})
            showNotificationModal()
        }


        const allRoles = () => {
      role.value = 99
      search()
    }

    const rolePatient = () => {
      role.value = 0
      search()
    }

    const roleDoctor = () => {
      role.value = 1
      search()
    }

    const roleAdmin = () => {
      role.value = 2
      search()
    }

            onMounted(() => {
            store.dispatch("Admin/LIST_USERS",{
                search_key: '',
                per_page: 15,
                current_page: 1,
                role: role.value
            })})

        return {
            search_field, current_page, editUserModalVisible, clincListPatientsModalVisible, patientListClinicsModalVisible, selected_user, search_key, per_page,showEditUserModal, 
            load, search, editUserModalClosed, showListClinicPatientsModal, listClinicPatientsModalClosed, listPatientClinicsModalClosed, showListPatientClinicsModal,
            listPatientsForClinicModal, listClinicsForPatientModal, fkClinicChanged, sendEmail,showSetPasswordModal,setPasswordModalClosed, setPasswordModalVisible, notifyClinician, 
            notificationModalClosed, notificationModalVisible, showNotificationModal, showDeleteUserModal, deleteUserModalVisible, deleteUserModalClosed,allRoles,rolePatient,roleDoctor,roleAdmin
        }
    },
    components: {
    ErrorList,
    EditUserModal,
    ListPatientsForClinicModal,
    ListClinicsForPatientModal,
    SetPasswordModal,
    NotificationModal,
    DeleteUserModal
    },
    computed: {
          ...mapGetters({ ERRORS: 'Admin/ERRORS',
                    ERRORS2: 'Admin/ERRORS2', 
                    USERS_LIST: 'Admin/USERS_LIST',
                    NOTIFICATION: 'Admin/NOTIFICATION'
            }),
    }
})
</script>