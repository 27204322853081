<template>
  <div class="container p-5">
    <error-list :errors="ERRORS" />
    <success title="Passwort erfolgreich geändert." v-if="SHOW_SUCCESS == 1" backToLogin="true"/>

    <div class="row">
      <div class="col-sm-3"></div>
      <div class="col-sm-6">
        <div class="card text-center" v-if="!ERRORS.length > 0">
          <h5 class="card-header">
            <p v-if="!password_set && !password_link_expired">Hallo, {{ username }}. Bitte legen Sie ein Passwort fest.</p>
            <p v-if="!password_set && !password_link_expired" style="color: orange">Passwort-Richtlinien: mind. 8 Zeichen, 1 Kleinbuchstabe, 1 Großbuchstabe, 1 Zahl, ein Sonderzeichen (z.B. !$?)</p>
            <p v-if="password_set">Sie haben Ihr Passwort bereits gesetzt</p>
            <p v-if="password_link_expired">	Der Link zum Passwort-Setzen ist nach {{ password_link_config }} Tagen abgelaufen.
              Bitte wenden Sie sich an die Studienzentrale. (Button "Kontakt")</p>
            <a v-if="password_set || password_link_expired" href='/'>Zurück zur Startseite</a>
          </h5>
          <form
           v-if="!password_set && !password_link_expired"
            class="needs-validation p-5"
            novalidate="true"
            @submit.prevent="submit"
          >
                <button type="button" class="btn btn-primary" @click="togglePassword">
                  <div v-if="hidePasswordFlag">Passwort anzeigen</div>
                  <div v-if="!hidePasswordFlag">Passwort verbergen</div></button>
            <div class="input-group mb-3">
              <input
                v-model="password1"
                :class="
                  'form-control ' +
                  (PASSWORD1_FEEDBACK != '' ? 'is-invalid' : '')
                "
                placeholder="Neues Passwort"
                :type="passwordFieldType"
                required
                @keydown="handleInput"
              />
              <div class="invalid-feedback">
                {{ PASSWORD1_FEEDBACK }}
              </div>
            </div>
            <div class="input-group mb-3">
              <input
                v-model="password2"
                :class="
                  'form-control ' +
                  (PASSWORD2_FEEDBACK != '' ? 'is-invalid' : '')
                "
                placeholder="Passwort Wiederholen"
                :type="passwordFieldType"
                required
                @keydown="handleInput"
              />
              <div class="invalid-feedback">
                {{ PASSWORD2_FEEDBACK }}
              </div>
            </div>
            <button type="submit" class="btn btn-primary">Absenden</button>
          </form>
        </div>
      </div>
    </div>
    <div class="col-sm-3"></div>
  </div>
</template>

<script>
import { browserInfo } from "@/core/mixins/vue_browser_detect";
import ErrorList from "@/core/components/ErrorList";
import Success from "@/core/components/Success";
import { ref, onMounted, computed } from "vue";
import { mapGetters, useStore } from "vuex";
import { useRoute } from "vue-router";

export default {
  name: "SetPassword",
  components: {
    ErrorList,
    Success,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const publicPath = ref(process.env.BASE_URL);
    const property_errors = ref([]);
    const username = computed(() => store.getters["User/USERNAME"]);
    const password_set = computed(() => store.getters["User/PASSWORD_SET"]);
    const password_link_expired = computed(() => store.getters["User/PASSWORD_LINK_EXPIRED"]);
    const password_link_config = computed(() => store.getters["User/PASSWORD_LINK_CONFIG"]);
    const password1 = ref("");
    const password2 = ref("");
    const success = ref(0);
    const token = route.params.token;
    const hidePasswordFlag = ref(true);
    const passwordFieldType = computed(() => hidePasswordFlag.value == true ? "password" : "text");

    onMounted(() => {
      clearFeedback();
      initUser();
    });

    const togglePassword = () => {
      hidePasswordFlag.value = !hidePasswordFlag.value;
    }

    const handleInput = (event) => {
      if (event.getModifierState("CapsLock")) {
        store.commit(
          "User/SET_PASSWORD1_FEEDBACK",
          "Warnung: die SHIFT-LOCK-Taste ist gedrückt."
        );
        store.commit(
          "User/SET_PASSWORD2_FEEDBACK",
          "Warnung: die SHIFT-LOCK-Taste ist gedrückt."
        );
      } else {
        clearFeedback();
      }
    };
    const submit = () => {
      store.dispatch("User/SET_PASSWORD_FROM_TOKEN", {
        token: token,
        password1: password1.value,
        password2: password2.value,
      });
    };
    const clearFeedback = () => {
      store.commit("User/SET_PASSWORD1_FEEDBACK", "");
      store.commit("User/SET_PASSWORD2_FEEDBACK", "");
      store.commit("User/SET_ERRORS", []);
    };

    const initUser = () => {
      store.dispatch("User/INIT_USER_FROM_TOKEN", { token: token });
    };

    return {
      publicPath,
      property_errors,
      username,
      password1,
      password2,
      success,
      handleInput,
      submit,
      initUser,
      passwordFieldType,
      togglePassword,
      hidePasswordFlag,
      password_set,
      password_link_expired,
      password_link_config
    };
  },
  created() {
    /***********************************************************************
     * hier wird geprüft, ob vom router eine error_message übergeben wurde.
     * siehe axios-instance.js.
     * hier werden alle 401-Fehler aufgefangen, die von Rails im
     * ApplicationController in der require_login! - Action gerendert werden.
     ***********************************************************************/

    let bi = browserInfo();
    if (bi.isIE) {
      this.errors2 = [
        "Bei der Verwendung des Internet-Explorers sind manche Funktionen eingeschränkt. Wir empfehlen die Benutzung von Chrome, Firefox oder Edge.",
      ];
    }
  },
  computed: {
    ...mapGetters({
      ERRORS: "User/ERRORS",
      SHOW_SUCCESS: "User/SHOW_SUCCESS",
      OLD_PASSWORD_FEEDBACK: "User/OLD_PASSWORD_FEEDBACK",
      PASSWORD1_FEEDBACK: "User/PASSWORD1_FEEDBACK",
      PASSWORD2_FEEDBACK: "User/PASSWORD2_FEEDBACK",
    }),
    browserInfo() {
      return browserInfo;
    },

    errors: {
      get() {
        return this.ERRORS;
      },
      set(value) {
        this.$store.commit("USER/SET_ERRORS", value);
      },
    },
  },
};
</script>

<style>
#messages {
  min-height: 5rem;
}
#title {
  margin-top: 2rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-size: 2rem;
  text-align: center;
}
</style>
