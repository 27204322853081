<template>
    <div class="modal fade" 
        ref="emailSuccessModal" 
        tabIndex="-1" 
        aria-labelledby="emailSuccessModalLabel"
        :class="{show: show, 'd-block': show}"
        aria-hidden="true"> 
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title col" id="editUserModalLabel">{{ notification }}</h5>
                    <button type="button" class="btn-close col-2" @click="close()" aria-label="Schließen"></button>
                </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" @click="close()">Schließen</button> 
                        <button v-if="showSaveButton" type="button" class="btn btn-primary" @click="save()">Speichern</button> 
                    </div>
    </div>
    </div>
</div>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    methods: {
        close(){
            this.$emit("modalClosed")
        },
        save(){
            this.$emit("saveButtonClicked")
        },
    },
    computed: {
          
    },
    props: {
        show: {type: Boolean, default: false},
        notification: {type: String, default: ""},
        showSaveButton: {type: Boolean, default: false},
    },
})
</script>