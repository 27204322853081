import router from '@/router'

export default {
  get user_name () {
    return [localStorage.getItem('first_name'), localStorage.getItem('last_name')].filter(n => n).join(' ')
  },
  get user_type () {
    return localStorage.getItem('user_type')
  },
  get is_password_change_required () {
    return localStorage.getItem('is_password_change_required').toString() === 'true'
  },

  set_user (data) {
    localStorage.setItem('first_name', data.user.first_name)
    localStorage.setItem('last_name', data.user.last_name)
    localStorage.setItem('fk_session', data.user.fk_session)
    localStorage.setItem('id', data.user.id)
    localStorage.setItem('user_type', data.user.user_type)
    localStorage.setItem('therapy_arm', data.user.therapy_arm)
    localStorage.setItem('is_logged_in', 1)
    localStorage.setItem('is_password_change_required', data.user.is_password_change_required)
    localStorage.setItem('show_therapy_questions', data.user.show_therapy_questions)
    localStorage.setItem('is_testinstance', data.user.is_testinstance)
  },

  drop_user () {
    localStorage.setItem('first_name', '')
    localStorage.setItem('last_name', '')
    localStorage.setItem('fk_session', '')
    localStorage.setItem('is_logged_in', 0)
    localStorage.setItem('is_password_change_required', false)
    router.push('/')
  },

  token () {
    return 'Bearer ' + localStorage.getItem('fk_session')
  }
}
