import api from '@/core/api'
import auth from "@/core/auth"
import router from '@/router'

/***********
 *  Actions
 ***********/
 const actions = {
  CLEAR_CHANGE_PASSWORD: async (context) => {
    context.commit("SET_CHANGE_PASSWORD_FEEDBACK", [])
    context.commit("SET_CHANGE_PASSWORD_SUCCESS", 0)
    context.commit("SET_ERRORS",[])
  },
  LOGIN: async (context, payload ) => {
    context.commit("SET_ERRORS",[])
      api.post('/users/login', {user_name: payload.user_name, password: payload.password})
      .then(response => {
        if(response.data.error){
          context.commit("SET_ERRORS",[response.data.error])
        }else{
          auth.set_user(response.data)
          router.push('/')
          context.commit("SET_ERRORS",[])
        }
      })
      .catch(error => {
        context.commit("SET_ERRORS",[error.response.data.error])
        auth.drop_user()
      })
  },
  CHANGE_PASSWORD: async (context, payload) => {
    context.dispatch("CLEAR_CHANGE_PASSWORD")
    try{
    let response = await api.post('/users/change_password', {old_password: payload.old_password, password1: payload.password1, password2: payload.password2 })
    if(response.status == 200){
      context.commit("SET_CHANGE_PASSWORD_SUCCESS", 1)
      if(response.data.error){
        context.commit("SET_ERRORS",[response.data.error])
      }else{
        context.commit("SET_CHANGE_PASSWORD_FEEDBACK", response.data)
        context.commit("SET_ERRORS",[])
      }
    }
    }catch(error){
      if(error.response && error.response.status == 422){
        if(error.response && error.response.data){
          if(error.response.data.error){
          context.commit("SET_ERRORS",[error.response.data.error])
          }
  
          context.commit("SET_CHANGE_PASSWORD_FEEDBACK", error.response.data)
          context.commit("SET_CHANGE_PASSWORD_SUCCESS", 0)
        }
      }else{
        if(error.response && error.response.data && error.response.data.error){
          context.commit("SET_ERRORS",[error.response.data.error])
        }
      }
    }
  },
  LIST_PATIENTS_FOR_CLINIC: async (context, payload) => {
    context.commit("SET_ERRORS",[])
    try{
      let response = await api.get('/users/list_patients_for_clinic/page/' + payload.current_page + '/perPage/' + payload.per_page + "/fk_clinic/" + payload.fk_clinic + "/searchKey/" + payload.search_key)
      if(response.status == 200){
        if(response.data.error){
          context.commit("SET_ERRORS",[response.data.error])
        }else{
        context.commit("SET_PATIENTS_LIST", response.data)
        context.commit("SET_ERRORS",[])
        }
      }
    }catch(error){
      if(error.response && error.response.status == 422){
        if(error.response && error.response.data && error.response.data.error){
          context.commit("SET_ERRORS",[error.response.data.error])
        }
      }else{
        if(error.response && error.response.data && error.response.data.error){
          context.commit("SET_ERRORS",[error.response.data.error])
        }
      }
  }
  },
  INIT_USER_FROM_TOKEN: async (context, payload) => {
    context.commit("SET_ERRORS",[])
    api.post('/users/get_user_by_token/' + payload.token)
    .then(response => {
      if(response.data.user && response.data.user.error ){
        context.commit("SET_ERRORS",response.data.error)
      }else{
        context.commit("SET_USERNAME", response.data.user_name)
        context.commit("SET_PASSWORD_SET", response.data.password_set)
        context.commit("SET_PASSWORD_LINK_EXPIRED", response.data.password_link_expired)
        context.commit("SET_PASSWORD_LINK_CONFIG", response.data.password_link_config)
        context.commit("SET_ERRORS",[])
      }
    })
    .catch(error => {
      if(error.response && error.response.data){
        if(error.response.data.user.error && error.response.data.user.error.length > 0){
          context.commit("SET_ERRORS",error.response.data.user.error)
        }
      }

    })
  },
  SET_PASSWORD_FROM_TOKEN: async (context, payload) => {
    context.dispatch("CLEAR_CHANGE_PASSWORD")
    api.post('/users/set_password_from_token/' + payload.token,  {password1: payload.password1, password2: payload.password2})
    .then(response => {
      if(response.status == 200){
        if(response.data.user.error){
          context.commit("SET_ERRORS",[response.data.user.error])
        }else{
          try{
          context.commit("SET_CHANGE_PASSWORD_SUCCESS", 1)
          context.commit("SET_ERRORS",[])
          context.commit("SET_CHANGE_PASSWORD_FEEDBACK","")
          }catch(e){
            e;
          }
        }
      }
    })
    .catch(error => {
      if(error.response && error.response.status == 422){
        if(error.response && error.response.data && error.response.data.user){
          if(error.response.data.user.error){
          context.commit("SET_ERRORS",[error.response.data.user.error])
          }
          context.commit("SET_CHANGE_PASSWORD_FEEDBACK", error.response.data)
          context.commit("SET_CHANGE_PASSWORD_SUCCESS", 0)
        }
      }else{
        if(error.response && error.response.data && error.response.data.error){
          context.commit("SET_ERRORS",[error.response.data.error])
        }
      }
    })
  },

  NEW_PASSWORD_CHECK_USER: async (context, payload) => {
    context.commit("SET_ERRORS",[])
    api.post('/users/new_password_check_user/', {user_field: payload.user_field})
    .then(response => {
      context.commit("SET_SUCCESS", 1)
      if(response.data.error){
        context.commit("SET_ERRORS",[response.data.error])
      }else{
        context.commit("SET_ERRORS",[])
      }
    })
    .catch(error => {
      if(error.response && error.response.data){
        console.log(error.response.data)
        if(error.response.data.user.error && error.response.data.user.error.length > 0){
          context.commit("SET_ERRORS",[error.response.data.user.error])
          context.commit("SET_SUCCESS", 0)
        }
      }

    })
  },

  CONTACT: async (context, payload) => {
    context.commit("SET_ERRORS",[])
    context.commit("SET_CONTACT_FEEDBACK", {error: []})
    api.post('/users/contact', {name: payload.name, email: payload.email, title: payload.title, message: payload.message, answer_given: payload.answer_given, quiz_id: payload.quiz_id })
    .then(response => {
      context.commit("SET_CONTACT_SUCCESS", 1)
      if(response.data.error){
        context.commit("SET_ERRORS",[response.data.error])
        context.commit("SET_QUIZ", response.data.quiz)
        context.commit("SET_CONTACT_FEEDBACK", response.data)
      }else{
        context.commit("SET_ERRORS",[])
        context.commit("SET_QUIZ", response.data.quiz)
        context.commit("SET_CONTACT_FEEDBACK", {error: []})
      }
    })
    .catch(error => {
      if(error.response && error.response.data){
        context.commit("SET_CONTACT_FEEDBACK", error.response.data)
        context.commit("SET_QUIZ", error.response.data.quiz)
        if(error.response.data.error && error.response.data.error.length > 0){
          context.commit("SET_ERRORS",[error.response.data.error])
          context.commit("SET_CONTACT_SUCCESS", 0)
        }
      }

    })
  },
  GET_QUIZ: async (context) => {
    context.commit("SET_ERRORS",[])
    api.get('/users/quiz')
    .then(response => {
      //context.commit("SET_SUCCESS", 1)
      if(response.data.error){
        context.commit("SET_ERRORS",[response.data.error])
      }else{
        context.commit("SET_QUIZ", response.data)
        context.commit("SET_ERRORS",[])
      }
    })
    .catch(error => {
      if(error.response && error.response.data){
        if(error.response.data.error && error.response.data.error.length > 0){
          context.commit("SET_ERRORS",[error.response.data.error])
          //context.commit("SET_SUCCESS", 0)
        }
      }

    })
  },


  PASSWORD_RECOVERY_CHECK_TOKEN: async (context, payload) => {
    context.commit("SET_ERRORS",[])
    api.post('/users/password_recovery_check_token/', {token: payload.token})
    .then(response => {
      if(response.data.error){
        context.commit("SET_ERRORS",[response.data.error])
      }else{
        if(response.status == 200){
          context.commit("SET_TOKEN_VALID", true)
        }else{
          context.commit("SET_TOKEN_VALID", false)
        }
        context.commit("SET_ERRORS",[])
      }
    })
    .catch(error => {
      if(error.response && error.response.data){
        if(error.response.data.error && error.response.data.error.length > 0){
          context.commit("SET_ERRORS",[error.response.data.error])
        }
      }

    })
  },

  PASSWORD_RECOVERY_SET_PASSWORD: async (context, payload) => {
    context.commit("SET_ERRORS",[])
    api.post('/users/password_recovery_set_password/', {token: payload.token, password1: payload.password1, password2: payload.password2})
    .then(response => {
      context.commit("SET_SUCCESS", 1)
      if(response.data.error){
        context.commit("SET_PASSWORD_RECOVERY_FEEDBACK", response.data)
        context.commit("SET_ERRORS",[response.data.error])
      }else{
        context.commit("SET_ERRORS",[])
      }
    })
    .catch(error => {
      if(error.response && error.response.data){
        context.commit("SET_SUCCESS", 0)
        context.commit("SET_PASSWORD_RECOVERY_FEEDBACK", error.response.data)
        if(error.response.data.error && error.response.data.error.length > 0){
          context.commit("SET_ERRORS",[error.response.data.error])
        }
      }

    })
  },
  LIST_THERAPISTS: async (context, payload) => {
    context.commit("SET_ERRORS",[])
    try{
      let response = await api.get('/users/therapists/list_therapists/page/' + payload.current_page + '/perPage/' + payload.per_page + "/searchKey/" + payload.search_key)
      if(response.status == 200){
        if(response.data && response.data.error){
          context.commit("SET_ERRORS",[response.data.error])
        }else{
        context.commit("SET_THERAPISTS_LIST", response.data)
        context.commit("SET_ERRORS",[])
        }
      }
    }catch(error){
      if(error.response && error.response.status == 422){
        if(error.response && error.response.data && error.response.data.error){
          context.commit("SET_ERRORS",[error.response.data.error])
        }
      }else{
        if(error.response && error.response.data && error.response.data.error){
          context.commit("SET_ERRORS",[error.response.data.error])
        }
      }
  }
  },
  SET_NOTIFICATION: async (context, payload) => {
    context.commit("SET_ERRORS",[])
    try{
      let response = await api.post('/users/notification', payload)
      if(response.status == 200){
        if(response.data && response.data.error){
          context.commit("SET_ERRORS",[response.data.error])
        }else{
          context.commit("SET_NOTIFICATION", payload)
        context.commit("SET_ERRORS",[])
        }
      }
    }catch(error){
      if(error.response && error.response.status == 422){
        if(error.response && error.response.data && error.response.data.error){
          context.commit("SET_ERRORS",[error.response.data.error])
        }
      }else{
        if(error.response && error.response.data && error.response.data.error){
          context.commit("SET_ERRORS",[error.response.data.error])
        }
      }
  }
  },
  LOAD_NOTIFICATION: async (context) => {
    context.commit("SET_ERRORS",[])
    try{
      let response = await api.get('/users/notification')
      if(response.status == 200){
        if(response.data && response.data.error){
          context.commit("SET_ERRORS",[response.data.error])
        }else{
          context.commit("SET_NOTIFICATION", {value: response.data.notification})
        context.commit("SET_ERRORS",[])
        }
      }
    }catch(error){
      if(error.response && error.response.status == 422){
        if(error.response && error.response.data && error.response.data.error){
          context.commit("SET_ERRORS",[error.response.data.error])
        }
      }else{
        if(error.response && error.response.data && error.response.data.error){
          context.commit("SET_ERRORS",[error.response.data.error])
        }
      }
  }
  },
  GET_PERSON_STRING: async (context) => {
    context.commit("SET_ERRORS",[])
    try{
      let response = await api.get('/users/person_string')
      if(response.status == 200){
        if(response.data && response.data.error){
          context.commit("SET_ERRORS",[response.data.error])
        }else{
          context.commit("SET_PERSON_STRING", {value: response.data})
        context.commit("SET_ERRORS",[])
        }
      }
    }catch(error){
      if(error.response && error.response.status == 422){
        if(error.response && error.response.data && error.response.data.error){
          context.commit("SET_ERRORS",[error.response.data.error])
        }
      }else{
        if(error.response && error.response.data && error.response.data.error){
          context.commit("SET_ERRORS",[error.response.data.error])
        }
      }
  }
  },
  SET_SHOW_THERAPY_QUESTIONS: async (context, payload) => {
    await context.commit("SET_SHOW_THERAPY_QUESTIONS",payload)
  },
  SET_MEASUREMENTS_LIST_MODAL_VISIBLE: (context, payload) => {
    context.commit("SET_MEASUREMENTS_LIST_MODAL_VISIBLE",payload)
  },
  CREATE_TESTUSER: async (context) => {
    context.commit("SET_ERRORS",[])
    try{
      let response = await api.get('/users/create_testuser')
      if(response.status == 200){
        if(response.data.error){
          context.commit("SET_ERRORS",[response.data.error])
        }else{
          context.commit("SET_TESTUSER_DATA",response.data)
          context.commit("SET_ERRORS",[])
        }
      }
    }catch(error){
      if(error.response && error.response.status == 422){
        if(error.response && error.response.data && error.response.data.error){
          context.commit("SET_ERRORS",[error.response.data.error])
        }
      }else{
        if(error.response && error.response.data && error.response.data.error){
          context.commit("SET_ERRORS",[error.response.data.error])
        }
      }
  }
  },
}


export default actions