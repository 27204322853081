<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-navbar">
    <div class="container-fluid">
      <div class="navbar-brand" href="#">
        LePaLuMo
      </div>
      <button 
        class="navbar-toggler" 
        type="button" 
        data-bs-toggle="collapse" 
        data-bs-target="#navbarSupportedContent" 
        aria-controls="navbarSupportedContent" 
        aria-expanded="false" 
        aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Ihr Benutzerkonto
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><a class="dropdown-item" href="#" @click="changePassword">Passwort ändern</a></li>
            </ul>
          </li>
          <div v-if="this.is_admin">
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Benutzerverwaltung
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <li><a class="dropdown-item" href="#" @click="newUser">Neuen Benutzer anlegen</a></li>
                <li><a class="dropdown-item" href="#" @click="listUsers">Benutzerliste</a></li>
                <li><a class="dropdown-item" href="#" @click="listMeasurements">Messungen</a></li>
                <li> <a class="dropdown-item" href="#">Therapeuten &raquo;</a>
                  <ul class="dropdown-menu dropdown-submenu">
                    <li>
                      <a class="dropdown-item" href="#" @click="categories">Kategorien</a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#" @click="edit_category">Kategorie hinzufügen</a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#" @click="therapists">Therapeuten</a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#" @click="edit_therapists">Therapeuten hinzufügen</a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#" @click="list_therapist_pdf">Therapeuten exportieren</a>
                    </li>
                  </ul>
                </li>
                <li> <a class="dropdown-item" href="#">Auswertungen &raquo;</a>
                  <ul class="dropdown-menu dropdown-submenu">
                  <a class="dropdown-item" href="#" @click="report_zusatzfragen">Report: Zusatzfragen</a>
                  </ul>
                </li>
              </ul>
            </li>
          </div>
          <li class="nav-item">
            <a class="nav-link" href="#" tabindex="-1"  @click="home()">Übersicht</a>
          </li>
          <div v-if="this.is_clinic">
            <li class="nav-item">
            <a class="nav-link" href="#" tabindex="-1"  @click="therapists2()">Therapeuten</a>
          </li>
          </div>
          <div v-if="this.is_clinic">
            <li class="nav-item">
            <a class="nav-link" href="#" tabindex="-1"  @click="messages()">Benachrichtigungen</a>
          </li>
          </div>
           <li class="nav-item">
            <a class="nav-link" href="#" tabindex="-1"  @click="impressum()">Impressum</a>
          </li>
               <li class="nav-item">
            <a class="nav-link" href="#" tabindex="-1"  @click="contact()">Kontakt</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#" tabindex="-1"  @click="logout">Abmelden</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  data(){
    return {
      is_admin: localStorage.getItem('user_type')  ? JSON.parse(localStorage.getItem('user_type')).enum == 2: false,
      is_clinic: localStorage.getItem('user_type')  ? JSON.parse(localStorage.getItem('user_type')).enum == 1: false

    }
  },
  methods:{
    changePassword(){
      this.$router.push("/users/change_password")
    },
    logout(){
      localStorage.clear()
    },
    newUser(){
      this.$store.commit("Admin/CLEAR_REGISTER_KEY")
      this.$router.push("/admins/register")
    },
    listUsers(){
      this.$router.push("/admins/list_users")
    },
    home(){
      this.$router.push("/")
    },
    impressum(){
      this.$router.push("/admins/impressum")
    },
    contact(){
      this.$router.push("/users/contact")
    },
    categories(){
      this.$router.push("/admins/categories")
    },
    therapists(){
      this.$router.push("/admins/therapists")
    },
    therapists2(){
      this.$router.push("/users/therapists")
    },
    messages(){
      this.$router.push("/users/messages")
    },
       edit_therapists(){
      this.$router.push("/admins/edit_therapists/new")
    },
        edit_category(){
      this.$router.push("/admins/edit_category/new")
    },
    list_therapist_pdf(){
      this.$router.push("/admins/list_therapist_pdf")
    },
    listMeasurements(){
      this.$router.push("/admins/list_measurements")
    },
    report_zusatzfragen(){
      this.$router.push("/admins/report_zusatzfragen")
    }
  }
})
</script>

<style scoped>
.bg-navbar {
  background-color: rgba(154, 190, 211, 0.842);
}
.dropdown-menu li {
position: relative;
}
.dropdown-menu .dropdown-submenu {
display: none;
position: absolute;
left: 100%;
top: -7px;
}
.dropdown-menu .dropdown-submenu-left {
right: 100%;
left: auto;
}
.dropdown-menu > li:hover > .dropdown-submenu {
display: block;
}
</style>