const initialState = function () {
  return {
    errors: [],
    errors2: [],
    password1_feedback: '',
    password2_feedback: '',
    last_name_feedback: '',
    first_name_feedback: '',
    user_name_feedback: '',
    title_feedback: '',
    therapy_arm_feedback: '',
    measurement0_feedback: '',
    measurement1_feedback: '',
    measurement2_feedback: '',
    measurement3_feedback: '',
    measurement4_feedback: '',
    measurement5_feedback: '',
    measurement6_feedback: '',
    study_number_feedback: '',
    dropped_out_at_feedback: '',
    email_feedback: '',
    users_list: [],
    patients_list: {users: []},
    clinics_list: {users: []},
    measurement_count: [],
    fk_clinic: 0,
    register_key: 0,
    categories: [],
    therapists: [],
    category: {},
    scores: [],
    show_success: false,
    new_user_success: false,
    new_user_id: 0,
    notification: "",
    regions: [],
    region: 0,
    sex: 0
  }
}

const initialTherapist = function() {
  return {
    title: '',
    last_name: '',
    first_name: '',
    street: '',
    email: '',
    phone: '',
    postal_code: '',
    city: '',
    category: 0,
  }
}

const state = {
  admin: initialState(),
}

export { state, initialState, initialTherapist}