<template>
  <div class="card col bigcard">
    <div class="card-body">
      <img :src="imageSrc" v-if="imageSrc"/>
      <p v-if="!imageSrc" Vielen Dank für das Erstellen der Messung. ></p>
      <div v-if="user_is_patient && gotworsecount > 0">
        <p>In der oben abgebildeten Grafik (LQ-Profil) können Sie Ihre Ergebnisse aus der Lebensqualitäts-Befragung einsehen.
          Ihre Ergebnisse deuten in den Bereichen:
          <ul>
            <li v-for="item in GOTWORSEITEMS.items" :key="item">{{ item }}</li>
          </ul> aufgrund eines Wertes unter "50" auf eine "reduzierte" Lebensqualität hin. 
Bitte nutzen Sie die Informationen und scheuen Sie sich nicht, das LQ-Profil mit Ihrem behandelnden Arzt bzw. Ihrer behandelnden Ärztin zu besprechen. 
Möglicherweise werden in diesem Gespräch weiterführende Therapieangebote erörtert und Ihr Arzt/Ihre Ärztin kann entscheiden, ob die Kontaktaufnahme mit regionalen VersorgerInnen für Sie sinnvoll ist.</p>
      
      </div>
    
    </div>
 </div>
</template>
<script>
import { defineComponent} from "vue";
import { mapGetters } from "vuex";
import axios from "@/core/api";

export default defineComponent({
  data(){
    return {
      imageSrc: null,
      user_is_patient: localStorage.getItem('user_type')  ? JSON.parse(localStorage.getItem('user_type')).enum == 0: false,
    }
  },
  computed: {
    ...mapGetters({
      GOTWORSEITEMS: "Measurements/GOTWORSEITEMS"
    }),

    gotworsecount() {
      return  this.GOTWORSEITEMS.items ?  this.GOTWORSEITEMS.items.length : []
    }
  },
  methods: {
    async loadImage() {
      //var fileName = this.USER_ID + ".png";
      var url = "/measurements/profile/";
      const response = await axios.get(url, { responseType: "blob" });
      if (response.status == 200) {
        const base64data = await await this.blobToData(response.data);
        this.imageSrc = base64data;
      }else{
        this.imageSrc = null;
      }
    },
    async blobToData(blob) {
      return new Promise((resolve) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.readAsDataURL(blob)
      })
    }
  },
  mounted() {
    //this.$store.dispatch("Measurements/LOAD_PROFILE");
    this.$store.dispatch("Measurements/LOAD_GOTWORSE_TEXT_LATEST");
    this.imageSrc = this.loadImage();
  },
});
</script>

