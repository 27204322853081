<template>
    <div class="card bigcard">
        <div class="list-group">
                <button
                :class="activeRegion == 0 ? 'list-group-item active list-group-item-primary list-group-item-action' : 'list-group-item list-group-item-primary list-group-item-action'"
                :aria-current="activeRegion == 0"
                value=""
                @click="changeRegion(0)">
                Alle Therapeuten
            </button>
            <button v-for="region in REGIONS.regions" :key="region.id"
            :class="activeRegion == region.id ? 'list-group-item active list-group-item-primary list-group-item-action' : 'list-group-item list-group-item-primary list-group-item-action'"
                :aria-current="activeRegion == 0"
                :value="region.name"
                @click="changeRegion(region.id)">
                {{region.name}}
        </button>
    </div>
        <button type="button" class="btn btn-primary" @click="this.downloadWithAxios()"> PDF herunterladen </button>
        <button type="button" class="btn btn-primary" @click="this.downloadWithAxiosExcel()"> Excel export </button>
    </div>
</template>
<script>
import { defineComponent, onMounted, ref } from 'vue'
import { useStore, mapGetters } from 'vuex'
import axios from '@/core/api'

export default defineComponent({
    setup() {
        const store = useStore()
        const activeRegion = ref(0) 

        const changeRegion = (id) => {
            activeRegion.value = id
        }

        const forceDownload = (response, fileName) => {
            const url = window.URL.createObjectURL(new Blob([response.data], {type: "application/pdf"}))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', fileName)
            link.setAttribute('target', '_blank')
            document.body.appendChild(link)
            link.click()
        }
           const downloadWithAxios = () => {
            var fileName = "Therapeuten.pdf"
               axios({
        method: 'post',
        url: './therapists/list_therapists_by_region_pdf/',
        data: {region: activeRegion.value, filter: ''},
        responseType: 'arraybuffer',
      }).then(response => {
                forceDownload(response, fileName)
            })
        }

        const downloadWithAxiosExcel = () => {
      var fileName = "therapists.xlsx";
      axios({
        method: "post",
        url: "/therapists_excel_export",
        responseType: "arraybuffer",
        data: {region: activeRegion.value, filter: ''}
      }).then((response) => {
        forceDownloadExcel(response, fileName);
      });
    }
    const forceDownloadExcel = (response, fileName) => {
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/vnd.ms-excel" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
    }

        onMounted(()=> {
            store.dispatch("Admin/LIST_REGIONS")
        }
        )


        return {
            downloadWithAxios, activeRegion, changeRegion, downloadWithAxiosExcel
        }
    },
    computed: {
    ...mapGetters({REGIONS: "Admin/REGIONS"}),
    }
})
</script>
