<template>
  <div class="alert alert-success" role="alert"
    :variant="variant"
    dismissible
  >
    <h4
      class="alert-heading"
    >
      {{ title }}
    </h4>

    <hr>
    <button type="button" class="btn btn-primary" @click="send()">Bestätigungslink senden.</button>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'SuccessNewUser',
  props: {
    title: { type: String, default: '' },
    variant: { type: String, default: 'success' },
    email: { type: String, default: 'success' }
  },
  methods:{
    send(){
      this.$store.dispatch("Admin/SEND_PASSWORD_LINK", {email: this.email, id: this.ID})
    }
  },
  computed: {
          ...mapGetters({ID: "Admin/NEW_USER_ID"}),
    }
}
</script>