<template>
        <div class="card col bigcard">
            <input type="text" class="form-control" placeholder="Username" v-model="user_name">
            <input type="text" class="form-control" placeholder="Password" v-model="password">
<button @click="send">
Measurement Reminder (Patient)
</button>
<button @click="send2">
Notifications (Arzt)
</button>
<button @click="send3">
    Registration Reminder 
</button>
<button @click="send4">
    Zufriedenheit Erinnerung
</button>
</div>
</template>
<script>

export default {
    name: "MeasurementReminder",
    data(){
        return {
            user_name: "",
            password: ""
        }
    },
    methods: {
        send(){
            this.$store.dispatch("Admin/SEND_MEASUREMENT_REMINDERS", {user_name: this.user_name, password: this.password})
        },
        send2(){
            this.$store.dispatch("Admin/SEND_PHYSICIAN_NOTIFICATIONS", {user_name: this.user_name, password: this.password})
        },
        send3(){
            this.$store.dispatch("Admin/SEND_REGISTRATION_REMINDERS", {user_name: this.user_name, password: this.password})
        },
        send4(){
            this.$store.dispatch("Admin/SEND_USER_SATISFACTION_REMINDER", {user_name: this.user_name, password: this.password})
        }
    }
}
</script>
