<template>
    <div class="modal fade" 
        ref="listClinicsForPatientModal" 
        tabIndex="-1" 
        aria-labelledby="listClinicsForPatientLabel"
        :class="{show: show, 'd-block': show}"
        aria-hidden="true"> 
        <div class="modal-dialog modal-fullwidth">
            <div class="modal-content">
                <success
      title="Änderungen erfolgreich gespeichert"
      v-if="SHOW_SUCCESS == true"
    />
                <div class="modal-header">
                    <h5 v-if="patient_id" class="modal-title col" id="listClinicsForPatientLabel">Username: {{user_name}} user_id: {{patient_id}}</h5>
                    <button type="button" class="btn-close col-2" @click="close()" aria-label="Schließen"></button>
                </div>
                <div class="modal-body">
                    <input 
                        type="text"
                        class="form-control"
                        placeholder="Suchbegriff"
                        aria-label="Suchbegriff"
                        aria-describedby="search"
                        v-model="search_key"
                        @keyup.enter="search(1)"
                        ref="search_field"
                    >
                    <button 
                        class="btn btn-primary"
                        type="button"
                        id="button-addon2"
                        @click="search(1)"
                    >
                    <i class="bi-button bi-search"></i>
                    </button>  
                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">Benutzername</th>
                                    <th scope="col">Benutzerstatus</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>    
                            <tbody> 
                                <tr v-for="user in PATIENTS_LIST.users" :key="user.id">
                                    <td>{{user.user_name}}</td>
                                    <td>{{JSON.parse(user.user_type).str}}</td>
                                    <td>
                                        <div class="form-check">
                                            <input 
                                                class="form-check-input" 
                                                type="checkbox" 
                                                id="checkIsVisible" 
                                                :checked="belongsToClinic(user.id)" 
                                                @change="setIsVisible(user, this.patient)" 
                                                :ref="isVisibleRef(this.patient)"
                                            >
                                            <label class="form-check label" for="checkIsVisible">
                                                Patient sichtbar?
                                            </label>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <caption v-if="PATIENTS_LIST.pagination">{{PATIENTS_LIST.pagination.count}} Kliniken gefunden. </caption>
                        </table>
                    </div>
                    <div class="row">
                        <nav v-if="PATIENTS_LIST.pagination" aria-label="Seitennavigation">
                            <ul class="pagination justify-content-center">
                                <li :class="PATIENTS_LIST.pagination.prevPage ? 'page-item' : 'page-item disabled'">
                                    <a class="page-link" href="#" aria-label="Zurück" @click="search(1)">
                                        <i class="bi-chevron-double-left"></i>
                                    </a>
                                </li>
                                <li :class="PATIENTS_LIST.pagination.prevPage ?  'page-item' : 'page-item disabled'">
                                    <a class="page-link" href="#" aria-label="Zurück" @click="search(PATIENTS_LIST.pagination.page-1)">
                                        <i class="bi-chevron-compact-left"></i>
                                    </a>
                                </li>
                                <li :class="i === PATIENTS_LIST.pagination.requestedPage ? 'page-item active' : 'page-item'" v-for="i in PATIENTS_LIST.pagination.pageCount" :key="i">
                                    <a class="page-link" @click="search(i)">{{i}}</a>
                                </li>
                                <li :class="PATIENTS_LIST.pagination.nextPage ? 'page-item' : 'page-item disabled'">
                                    <a class="page-link" href="#" aria-label="Nächste" @click="search(PATIENTS_LIST.pagination.page + 1)">
                                        <i class="bi-chevron-compact-right"></i>
                                    </a>
                                </li>
                                <li :class="PATIENTS_LIST.pagination.nextPage ? 'page-item' : 'page-item disabled'">
                                    <a class="page-link" href="#" aria-label="Nächste" @click="search(PATIENTS_LIST.pagination.pageCount)">
                                        <i class="bi-chevron-double-right"></i>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div class="modal-footer"><button class="btn btn-secondary" @click="close()">Schließen</button>
                    <!--<button type="button" class="btn btn-primary" @click="save()">Änderungen speichern</button>-->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent, ref, onMounted, computed,watch  } from 'vue'
import { mapGetters, useStore } from 'vuex'
import Success from "@/core/components/Success";

export default defineComponent({
    setup(props, context) {
        const store = useStore();
        const search_field = ref(null);
        const user_type = ref(0);
        const patient = computed(() => props.patient);
        const user_name = computed(() => patient.value ? patient.value.user_name : null)
        const patient_id = computed(() => patient.value ? patient.value.id : null)
        const current_page = ref(1)
        const search_key = ref('')
        const per_page = ref(15)
        const clinic_id =  ref(patient.value ? patient.value.fk_clinic : null)

        onMounted(() => {
               store.dispatch("Admin/LIST_ALL_CLINICS_FOR_PATIENT",{
                search_key: '',
                per_page: 15,
                current_page: 1,
                fk_patient: patient.value ? patient.value.id : null
            })
            search_field.value.focus()
        });

        const save = () => {
            //action isnt working, but changes are saved on checkbox click anyways
            //store.dispatch("Admin/EDIT_USER", {user_name: user_name.value, user_type: user_type.value})
        }

        const userTypeChanged = (event) => {
            user_type.value = parseInt(event.target.id)
        }

        const belongsToClinic = (id) => {
            return id == clinic_id.value
        }

        const isVisibleRef = (user) => {
            return "isVisible_" + user.id 
        }

        const search = (cp = 1) => {
            current_page.value = cp
            store.dispatch('Admin/LIST_ALL_CLINICS_FOR_PATIENT', {
                search_key: search_key.value,
                per_page: per_page.value,
                current_page: current_page.value,
                fk_patient: patient.value ? patient.value.id : null
            })  
            if(search_field.value !== null){
                search_field.value.focus()
            }
        }

        const close = () => {
            context.emit("modalClosed")
        }


        watch(() => props.patient, (value) => {
        clinic_id.value  = value.fk_clinic
    });

        return {
            search_field, user_name, save, user_type, patient_id, userTypeChanged, isVisibleRef,
            current_page, search_key, per_page, search, close, belongsToClinic, clinic_id
        }
    },
    props: {
        show: {type: Boolean, default: false},
        patient: {type: Object, default: null},
    },
    components: {
      Success,
    },

    computed:{
                  ...mapGetters({ ERRORS: 'Admin/ERRORS',
                    ERRORS2: 'Admin/ERRORS2', 
                    PATIENTS_LIST: 'Admin/CLINICS_LIST',
                    SHOW_SUCCESS: 'Admin/SHOW_SUCCESS',
            }),
    },

    methods: {
        setIsVisible(clinic, user){
            //ckecked: state of the checkbox before click
            let checked = (this.clinic_id == clinic.id)
            if(!checked){
                this.clinic_id  = clinic.id
                this.$emit("fkClinicChanged", {clinic: clinic, user: user});//this.$store.dispatch("Admin/SET_FK_CLINIC", {clinic: clinic, user: user})
            }else{
                this.clinic_id  = null
                this.$emit("fkClinicChanged", {clinic: null, user: user}); //this.$store.dispatch("Admin/SET_FK_CLINIC", {clinic: null, user: user})
            }
            this.search(this.current_page.value)
        },
    }
})
</script>