<template>
  <div class="card infocard2">
    <h5 class="card-title">Über den folgenden Button können Sie sich eine Anleitung der Anwendung herunterladen</h5>
  <button type="button" class="btn btn-primary" @click="this.downloadTutorialPdf()"> Anleitung herunterladen </button>
</div>
    <div class="card bigcard">
      <div class="card-body">
        <h5 class="card-title">Benutzer</h5>
        <hr />
        <input
          type="text"
          class="form-control"
          placeholder="Suchbegriff"
          aria-label="Suchbegriff"
          aria-describedby="search"
          v-model="search_key"
          @keyup.enter="search(1)"
          ref="search_field"
        />
        <button
          class="btn btn-primary"
          type="button"
          id="button-addon2"
          @click="search(1)"
        >
          <i class="bi-button bi-search"></i>
        </button>
        <div class="dropdown">
  <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    Filter nach Rolle
  </button>
  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
    <a class="dropdown-item" href="#" @click="allRoles">Alle Benutzer</a>
    <a class="dropdown-item" href="#" @click="rolePatient">Patienten</a>
    <a class="dropdown-item" href="#" @click="roleDoctor">Ärzte</a>
    <a class="dropdown-item" href="#" @click="roleAdmin">Vertrauensstelle</a>
  </div>
</div>
        <table class="table table-striped table-responsive">
          <thead>
            <tr>
              <th scope="col">Nachname</th>
              <th scope="col">Vorname</th>
              <th scope="col">Benutzer</th>
              <th scope="col">Rolle</th>
              <th scope="col">Klinik/Praxis</th>
              <th scope="col">Therapieabschnitt</th>
              <th scope="col">Anzahl Messungen</th>
              <th scope="col">Einsehen</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="patient in PATIENT_LIST.users" :key="patient.user_name" :class="patient.dropped_out_at != null ? 'table-danger' : 'table'">
              <td>{{ patient.last_name }}</td>
              <td>{{ patient.first_name }}</td>
              <td>{{ patient.user_name }}</td>
              <td>{{JSON.parse(patient.user_type).str}}</td>
              <td>{{ patient.clinic_name }}</td>
              <td>{{ JSON.parse(patient.therapy_arm).str }}</td>
              <td>{{ patient.nr_of_measurements }}</td>
              <td>
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="showMeasurementListModal(patient.id)"
                  v-if="JSON.parse(patient.user_type).enum == 0"
                >
                  >
                </button>
              </td>
            </tr>
          </tbody>
          <caption v-if="PATIENT_LIST.pagination">
            {{
              PATIENT_LIST.pagination.count
            }}
            Patienten gefunden.
          </caption>
        </table>
      </div>
      <div class="row">
        <nav v-if="PATIENT_LIST.pagination" aria-label="Seitennavigation">
          <ul class="pagination justify-content-center">
            <li
              :class="
                PATIENT_LIST.pagination.prevPage
                  ? 'page-item'
                  : 'page-item disabled'
              "
            >
              <a
                class="page-link"
                href="#"
                aria-label="Zurück"
                @click="search(1)"
              >
                <i class="bi-chevron-double-left"></i>
              </a>
            </li>
            <li
              :class="
                PATIENT_LIST.pagination.prevPage
                  ? 'page-item'
                  : 'page-item disabled'
              "
            >
              <a
                class="page-link"
                href="#"
                aria-label="Zurück"
                @click="search(PATIENT_LIST.pagination.page - 1)"
              >
                <i class="bi-chevron-compact-left"></i>
              </a>
            </li>
            <li
              :class="
                i === PATIENT_LIST.pagination.requestedPage
                  ? 'page-item active'
                  : 'page-item'
              "
              v-for="i in PATIENT_LIST.pagination.pageCount"
              :key="i"
            >
              <a class="page-link" @click="search(i)">{{ i }}</a>
            </li>
            <li
              :class="
                PATIENT_LIST.pagination.nextPage
                  ? 'page-item'
                  : 'page-item disabled'
              "
            >
              <a
                class="page-link"
                href="#"
                aria-label="Nächste"
                @click="search(PATIENT_LIST.pagination.page + 1)"
              >
                <i class="bi-chevron-compact-right"></i>
              </a>
            </li>
            <li
              :class="
                PATIENT_LIST.pagination.nextPage
                  ? 'page-item'
                  : 'page-item disabled'
              "
            >
              <a
                class="page-link"
                href="#"
                aria-label="Nächste"
                @click="search(PATIENT_LIST.pagination.pageCount)"
              >
                <i class="bi-chevron-double-right"></i>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  <measurement-list-modal-admin
    ref="measurementListModal"
    :show="MEASUREMENTS_LIST_MODAL_VISIBLE && !isFetching"
    @modalClosed="measurementListModalClosed"
    :patientId="WELCOME_PAGE_SELECTED_PATIENT"
    v-if="WELCOME_PAGE_SELECTED_PATIENT"
  />
</template>
<script>
import { defineComponent, onMounted, ref } from "vue";
import { mapGetters, useStore } from "vuex";
import measurementListModalAdmin from "@/modules/measurements/components/MeasurementListModalAdmin";

export default defineComponent({
  components: {
    measurementListModalAdmin,
  },
  computed: {
    ...mapGetters({
      PATIENT_LIST: "Admin/USERS_LIST",
      MEASUREMENTS_LIST_MODAL_VISIBLE: "User/MEASUREMENTS_LIST_MODAL_VISIBLE",
      WELCOME_PAGE_SELECTED_PATIENT: "User/WELCOME_PAGE_SELECTED_PATIENT",
    }),
  },
  setup() {
    const store = useStore();
    const search_field = ref(null);
    const current_page = ref(1);
    const per_page = ref(15);
    const search_key = ref("");
    const role = ref(99);
    const API_URL =  (process.env.NODE_ENV === 'development') ? 'http://localhost:8765' : 'https://lepalumo.tumorzentrum-regensburg.de/lepalumo';
    const isFetching = ref(false);

    const downloadTutorialPdf = () => {
            var fileName = API_URL + '/resources/pdf/lepalumo_dok.pdf'
            const link = document.createElement("a");
      link.href = fileName;
      link.download = "Lepalumo_Anleitung";
      link.setAttribute("download", "Lepalumo_Anleitung");
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
          };

    onMounted(() => {
      search_field.value.focus();
    });

    const search = (cp = 1) => {
      current_page.value = cp;
      store.dispatch("Admin/LIST_USERS", {
        search_key: search_key.value,
        per_page: per_page.value,
        current_page: current_page.value,
        fk_clinic: localStorage.getItem("id"),
        role: role.value
      });
      if (search_field.value !== null) {
        search_field.value.focus();
      }
    };
    const showMeasurementListModal = async (patientId) => {
      //store.commit('User/SET_WELCOME_PAGE_SELECTED_PATIENT', patientId)
      isFetching.value = true;
      store.commit("User/SET_SHOW_THERAPY_QUESTIONS", true)
      store.commit("User/SET_MEASUREMENTS_LIST_MODAL_VISIBLE", {
        patientId: patientId,
        visible: true,
      });
      isFetching.value = false;    
    };

    const measurementListModalClosed = () => {
      store.commit("User/SET_MEASUREMENTS_LIST_MODAL_VISIBLE", {visible: false});
    };

    const allRoles = () => {
      role.value = 99
      search()
    }

    const rolePatient = () => {
      role.value = 0
      search()
    }

    const roleDoctor = () => {
      role.value = 1
      search()
    }

    const roleAdmin = () => {
      role.value = 2
      search()
    }

    store.dispatch("Admin/LIST_USERS", {
      search_key: search_key.value,
      per_page: per_page.value,
      current_page: current_page.value,
      fk_clinic: localStorage.getItem("id"),
      role: role.value
    });
    if (search_field.value !== null) {
      search_field.value.focus();
    }

    return {
      search_field,
      current_page,
      per_page,
      search_key,
      search,
      showMeasurementListModal,
      measurementListModalClosed,
      allRoles,
      rolePatient,
      roleDoctor,
      roleAdmin,
      downloadTutorialPdf,
      isFetching
    };
  },
});
</script>