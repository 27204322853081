const getters = {
  ERRORS: state => {
    return state.user.errors
  },
  ERRORS2: state => {
    return state.user.errors2
  },
  OLD_PASSWORD_FEEDBACK: state => {
    return state.user.old_password_feedback
  },
  PASSWORD1_FEEDBACK: state => {
    return state.user.password1_feedback
  },
  PASSWORD2_FEEDBACK: state => {
    return state.user.password2_feedback
  },
  USER_FIELD_FEEDBACK: state => {
    return state.user.user_field_feedback
  },
  SHOW_SUCCESS: state => {
    return state.user.show_success
  },
  USER_TYPE: state => {
    return state.user.user_type
  },
  ID: state => {
    return state.user.id
  },
  PDF_DATA: state => {
    return state.user.pdf_data 
  },
  PATIENTS_LIST: state => {
    return state.user.patients_list
  },
  MEASUREMENTS_LIST_MODAL_VISIBLE: state => {
    return state.user.measurements_list_modal_visible
  },
  NEW_MEASUREMENT_MODAL_VISIBLE: state => {
    return state.user.new_measurement_modal_visible
  },
  WELCOME_PAGE_SELECTED_PATIENT: state => {
    return state.user.welcome_page_selected_patient
  },
  USERNAME: state => {
    return state.user.username
  },
  TOKEN_VALID: state => {
    return state.user.token_valid
  },
  QUIZ: state => {
    return state.user.quiz
  },
  NAME_FEEDBACK: state => {
    return state.user.name_feedback
  },
  TITLE_FEEDBACK: state => {
    return state.user.title_feedback
  },
  MESSAGE_FEEDBACK: state => {
    return state.user.message_feedback
  },
  CAPTCHA_FEEDBACK: state => {
    return state.user.captcha_feedback
  },
  EMAIL_FEEDBACK: state => {
    return state.user.email_feedback
  },
  CONTACT_SUCCESS: state => {
    return state.user.contact_success
  },
  THERAPISTS: state => {
    return state.user.therapists
  },
  CATEGORIES: state => {
    return state.user.categories
  },
  REGIONS: state => {
    return state.user.regions
  },
  NOTIFICATION_SETTINGS: state => {
    return state.user.notification_settings
  },
  PERSON_STRING: state => {
    return state.user.person_string
  },
  SHOW_THERAPY_QUESTIONS: state => {
    return state.user.show_therapy_questions
  },
  PASSWORD_SET: state => {
    return state.user.password_set
  },
  PASSWORD_LINK_EXPIRED: state => {
    return state.user.password_link_expired
  },
  PASSWORD_LINK_CONFIG: state => {
    return state.user.password_link_config
  },
  TESTUSER_USER: state => {
    return state.user.testuser.user
  },
  TESTUSER_PASSWORD: state => {
    return state.user.testuser.password
  }
}

export default getters