<template>
    <div>
        <div class="card col bigcard">
            <button type="button" class="btn btn-primary" @click="downloadWithAxiosExcel">Report downloaden</button>
        </div>
    </div>
</template>
<script>
import { defineComponent} from 'vue'
import axios from '@/core/api'
export default defineComponent({
    setup() {
        const downloadWithAxiosExcel = () => {
      var fileName = "therapy_questions_export.xlsx";
      axios({
        method: "get",
        url: "/therapists_questions_answered_excel_export",
        responseType: "arraybuffer",
      }).then((response) => {
        forceDownloadExcel(response, fileName);
      });
    }
    const forceDownloadExcel = (response, fileName) => {
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/vnd.ms-excel" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
    }



        return {downloadWithAxiosExcel}
    }
})
</script>