const mutations = {
  SET_ERRORS: (state, payload) => {
    state.measurement.errors = payload
  },
  SET_ERRORS2: (state, payload) => {
    state.measurement.errors2 = payload
  },
  SET_MEASUREMENT: (state, payload) => {
    state.measurement.measurement = payload
  },
  SET_MEASUREMENTS: (state, payload) => {
    state.measurement.measurements = payload.measurements
    state.measurement.therapy_arm = payload.therapy_arm
    state.measurement.canCreateMeasurement = payload.canCreateMeasurement
    state.measurement.nextMeasurement = payload.nextMeasurement
    state.measurement.user_id = payload.user_id
  },
  SET_MEASUREMENTS_OVERVIEW: (state, payload) => {
    state.measurement.measurements_overview = payload.results
    state.measurement.duplicate_measurement_numbers = payload.duplicate_measurement_numbers
    state.measurement.missing_measurement_numbers = payload.missing_measurement_numbers
  },
  SET_MEASUREMENT_VALUE: (state, payload) => {
    state.measurement.measurement.values[payload.index] = payload.value
  },
  SET_MEASUREMENT_LC29_60_TEXT_VALUE: (state, payload) => {
    state.measurement.measurement.lc29_60_text = payload.value
  },
  SET_MEASUREMENT_LC29_61_TEXT_VALUE: (state, payload) => {
    state.measurement.measurement.lc29_61_text = payload.value
  },
  SET_MEASUREMENT_LC29_62_TEXT_VALUE: (state, payload) => {
    state.measurement.measurement.lc29_62_text = payload.value
  },
  SET_FK_USER: (state, payload) => {
    state.measurement.measurement.fk_user = payload.value
  },
  SET_IS_SELECTED: (state, payload) => {
    state.measurement.measurements.filter(measurement => measurement.id == payload.measurement.id)[0].is_selected = payload.is_selected
  },
  SET_MEASUREMENT_SUCCESS: (state, payload) => {
    state.measurement.show_success = payload
  },
  VALIDATE_MEASUREMENT: (state, payload) => {
    for(let i = 1; i <= 54; i++){
        let index_str = "q" + i + "ok"
        state.measurement.validation[index_str] = parseInt(payload.measurement.values[i - 1]) > 0
    }
    if(payload.is_2){ // SKIP Therapy Question Validation for Admin
      state.measurement.validation_therapy["therapy_questions_ok"] = true
      return
    }
    if (payload.show_therapy_questions) {
      var therapy_selected = parseInt(payload.measurement.therapy_1 | 0) > 0  
      || parseInt(payload.measurement.therapy_2 | 0) > 0 // the | 0 enforces conversion to int
      || parseInt(payload.measurement.therapy_3 | 0) > 0
      || parseInt(payload.measurement.therapy_4 | 0) > 0 
      || parseInt(payload.measurement.therapy_5 | 0) > 0 
      || parseInt(payload.measurement.therapy_6 | 0) > 0
      || parseInt(payload.measurement.therapy_7 | 0) > 0
      || parseInt(payload.measurement.therapy_8 | 0) > 0
      || parseInt(payload.measurement.therapy_9 | 0) > 0
      || parseInt(payload.measurement.therapy_10 | 0) > 0
      || parseInt(payload.measurement.therapy_11 | 0) > 0
      || parseInt(payload.measurement.therapy_12 | 0) > 0
      || payload.measurement.therapy_13_text != ""
      state.measurement.validation_therapy["therapy_selected"] = therapy_selected
      state.measurement.validation_therapy["therapy_questions_ok"] =  
      (parseInt(payload.measurement.therapien_nicht_wahrgenommen | 0) > 0  && therapy_selected == 0)
      || (parseInt(payload.measurement.therapien_nicht_wahrgenommen | 0) == 0  && therapy_selected > 0)
      
           
    }else{
      state.measurement.validation_therapy["therapy_questions_ok"] = true
    }
  },
  CLEAR_VALIDATE: (state) => {
    for(let i = 1; i <= 54; i++){
      let index_str = "q" + i + "ok"
      state.measurement.validation[index_str] = true
    }
  },
  SET_TEMPLATES_LIST: (state, payload) => {
    state.measurement.templates = payload
  },
  SET_TEMPLATE: (state, payload) => {
    state.measurement.template = payload
  },
  SET_PROFILE: (state, payload) => {
    state.measurement.profile = payload
  },
  SET_GOTWORSE_ITEMS: (state, payload) => {
    state.measurement.gotworseitems = payload
  },
  SET_NEW_COUNT: (state, payload) => {
    state.measurement.new_count = payload.count
  },
  SET_NEW_MEASUREMENTS: (state, payload) => {
    state.measurement.not_seen = payload.measurements
  },
  SET_THERAPIEN_NICHT_WAHRGENOMMEN: (state, payload) => {
    state.measurement.measurement.therapien_nicht_wahrgenommen = payload.value
  },
  SET_THERAPY_1: (state, payload) => {
    state.measurement.measurement.therapy_1 = payload.value
    if(payload.value === true)
      state.measurement.measurement.therapien_nicht_wahrgenommen = false
  },
  SET_THERAPY_2: (state, payload) => {
    state.measurement.measurement.therapy_2 = payload.value
    if(payload.value === true)
      state.measurement.measurement.therapien_nicht_wahrgenommen = false
  },
  SET_THERAPY_3: (state, payload) => {
    state.measurement.measurement.therapy_3 = payload.value
    if(payload.value === true)
      state.measurement.measurement.therapien_nicht_wahrgenommen = false
  },
  SET_THERAPY_4: (state, payload) => {
    state.measurement.measurement.therapy_4 = payload.value
    if(payload.value === true)
      state.measurement.measurement.therapien_nicht_wahrgenommen = false
  },
  SET_THERAPY_5: (state, payload) => {
    state.measurement.measurement.therapy_5 = payload.value
    if(payload.value === true)
      state.measurement.measurement.therapien_nicht_wahrgenommen = false
  },
  SET_THERAPY_6: (state, payload) => {
    state.measurement.measurement.therapy_6 = payload.value
    if(payload.value === true)
      state.measurement.measurement.therapien_nicht_wahrgenommen = false
  },
  SET_THERAPY_7: (state, payload) => {
    state.measurement.measurement.therapy_7 = payload.value
    if(payload.value === true)
      state.measurement.measurement.therapien_nicht_wahrgenommen = false
  },
  SET_THERAPY_8: (state, payload) => {
    state.measurement.measurement.therapy_8 = payload.value
    if(payload.value === true)
      state.measurement.measurement.therapien_nicht_wahrgenommen = false
  },
  SET_THERAPY_9: (state, payload) => {
    state.measurement.measurement.therapy_9 = payload.value
    if(payload.value === true)
      state.measurement.measurement.therapien_nicht_wahrgenommen = false
  },
  SET_THERAPY_10: (state, payload) => {
    state.measurement.measurement.therapy_10 = payload.value
    if(payload.value === true)
      state.measurement.measurement.therapien_nicht_wahrgenommen = false
  },
  SET_THERAPY_11: (state, payload) => {
    state.measurement.measurement.therapy_11 = payload.value
    if(payload.value === true)
      state.measurement.measurement.therapien_nicht_wahrgenommen = false
  },
  SET_THERAPY_12: (state, payload) => {
    state.measurement.measurement.therapy_12 = payload.value
    if(payload.value === true)
      state.measurement.measurement.therapien_nicht_wahrgenommen = false
  },
  SET_THERAPY_13_TEXT: (state, payload) => {
    state.measurement.measurement.therapy_13_text = payload.value
    if(payload.value !== "")
      state.measurement.measurement.therapien_nicht_wahrgenommen = false
  },
}

export default mutations