<template>
    <div class="modal fade" 
        ref="editUserModal" 
        tabIndex="-1" 
        aria-labelledby="editUserModalLabel"
        :class="{show: show, 'd-block': show}"
        aria-hidden="true"
        :key="key"> 
        <div class="modal-dialog">
            <div class="modal-content">
                  <success
      title="Änderungen erfolgreich gespeichert"
      v-if="SHOW_SUCCESS == true"
    />
            <error-list
            :errors="ERRORS"
        />
                <div class="modal-header">
                    <h5 class="modal-title col" id="editUserModalLabel">Benutzer bearbeiten</h5>
                    <button type="button" class="btn-close col-2" @click="close()" aria-label="Schließen"></button>
                </div>
                <div class="input-group mb-3">
                                  <input
            v-model="title"
            :class="'form-control ' + (TITLE_FEEDBACK != '' ? 'is-invalid' : '')"
            placeholder="Titel"
            required
          />
          <div class="invalid-feedback">
            {{ TITLE_FEEDBACK }}
          </div>
          </div>
                        <div class="input-group mb-3">
                                  <input
            v-model="first_name"
            :class="'form-control ' + (FIRST_NAME_FEEDBACK != '' ? 'is-invalid' : '')"
            placeholder="Vorname"
            required
          />
          <div class="invalid-feedback">
            {{ FIRST_NAME_FEEDBACK }}
          </div>
          </div>
                                <div class="input-group mb-3">
                                        <input
            v-model="last_name"
            :class="'form-control ' + (LAST_NAME_FEEDBACK != '' ? 'is-invalid' : '')"
            placeholder="Nachname"
            required
          />
          <div class="invalid-feedback">
            {{ LAST_NAME_FEEDBACK }}
          </div>
                    <div class="input-group mb-3 mt-3">
                      Studiennummer
                                  <input
            v-model="study_number"
            :class="'form-control ' + (STUDY_NUMBER_FEEDBACK != '' ? 'is-invalid' : '')"
            placeholder="Studiennummer"
            required
          />
          <div class="invalid-feedback">
            {{ STUDY_NUMBER_FEEDBACK }}
          </div>
          </div>
          <div class="input-group mb-3 mt-3">
             <label>Geschlecht</label>
             <select :value="sex" class="mb-6" @change="updateSex($event.target.value)">
              <option v-for="sex in [{id: 0, value: 'k.a'}, {id: 1, value: 'männlich'},{id: 2, value: 'weiblich'}]" :key="sex.id" :value="sex.id">  
                {{sex.value}}
              </option>
            </select>
             </div>
          </div>
          <div class="input-group mb-3">
          <input
            v-model="email"
            :class="'form-control ' + (EMAIL_FEEDBACK != '' ? 'is-invalid' : '')"
            placeholder="Email"
            required
          />
          <div class="invalid-feedback">
            {{ EMAIL_FEEDBACK }}
          </div>
          </div>
                    <div class="input-group mb-3 mt-3">
             <label>Studienregion</label>
            <select :value="this.region" class="mb-6" @change="updateRegion($event.target.value)">
              <option v-for="region in REGIONS.regions" :key="region.id" :value="region.id">  
                {{region.name}}
              </option>
            </select>
             </div>
             <div class="input-group mb-3 mt-3">
              Patient aus Studie ausgeschieden am: 
                                  <input
                                  type="date"
            v-model="dropped_out_at"
            :class="'form-control ' + (DROPPED_OUT_AT_FEEDBACK != '' ? 'is-invalid' : '')"
            placeholder="Studiennummer"
            required
          />
          <div class="invalid-feedback">
            {{ DROPPED_OUT_AT_FEEDBACK }}
          </div>
          <i class='bi bi-x-lg' style="color: red" @click="clearDroppedOut()"></i>
             </div>
                <div class="modal-body">
                    <h3>Benutzerrolle</h3>
                    <div class="form-check">
                        <input 
                            class="form-check-input" 
                            type="radio" 
                            :name='"radioUsertype_" + user_name'
                            id="0"
                            :checked="user_type_is_0"
                            @change="userTypeChanged($event)">
                        <label class="form-check-label" for="radioPatient">
                            Patient
                        </label>
                    </div>
                    <div class="form-check">
                        <input 
                            class="form-check-input" 
                            type="radio" 
                            :name='"radioUsertype_" + user_name'
                            id="1"
                            :checked="user_type_is_1"
                            @change="userTypeChanged($event)">
                        <label class="form-check-label" for="radioClinic">
                            Arztpraxis/Klinik
                        </label>
                    </div>
                    <div class="form-check">
                        <input 
                            class="form-check-input" 
                            type="radio" 
                            :name='"radioUsertype_" + user_name'
                            id="2"
                            :checked="user_type_is_2"
                            @change="userTypeChanged($event)">
                        <label class="form-check-label" for="radioTrusted">
                            Vertrauensstelle
                        </label>
                    </div>
                    <hr>
                    <div v-if="user_type_is_0">
                        <h3>Therapieabschnitt</h3>
                        <div class="form-check">
                            <input 
                                class="form-check-input" 
                                type="radio" 
                                :name='"radioTherapyarm_" + user_name'
                                id="0"
                                :checked="therapy_arm_is_0"
                                @change="therapyArmChanged($event)">
                            <label class="form-check-label" for="radioNoSelection">
                                k.A
                            </label>
                        </div>
                        <div class="form-check">
                            <input 
                                class="form-check-input" 
                                type="radio" 
                                :name='"radioTherapyarm_" + user_name'
                                id="1"
                                :checked="therapy_arm_is_1"
                                @change="therapyArmChanged($event)">
                            <label class="form-check-label" for="radioIntervention">
                                Interventionsabschnitt
                            </label>
                        </div>
                        <div class="form-check">
                            <input 
                                class="form-check-input" 
                                type="radio" 
                                :name='"radioTherapyarm_" + user_name'
                                id="2"
                                :checked="therapy_arm_is_2"
                                @change="therapyArmChanged($event)">
                            <label class="form-check-label" for="radioControl">
                                Kontrollabschnitt
                            </label>
                        </div>
                        <hr>
                        <h3>Messzeitpunkte</h3>
                        <input class="form-check-input" type="checkbox" v-model="measurement_on_paper" id="flexCheckChecked" :checked="measurement_on_paper == true">Messung auf Papier
                        <div class="row" >
                        <label for="measurement0">Messung 0 (Rekrutierungsdatum)</label>
                        <input @blur="measurement0Blur()"   :class="'form-control ' + (MEASUREMENT0_FEEDBACK != '' || measurement0_feedback != '' ? 'is-invalid' : '')" type="date" id="measurement0" name="measurement0"  v-model="measurement0">           
                        <div class="invalid-feedback">
                                    {{ measurement0_feedback }}
                            <br v-if="MEASUREMENT0_FEEDBACK != '' && measurement0_feedback != ''">
                            {{ MEASUREMENT0_FEEDBACK }}

                          </div>
                        </div>
                        <div class="row" >
                        <label for="measurement1">Messung 1</label>
                        <div class="input-group" >
                        <input @blur="measurement1Blur()"   :class="'form-control ' + (MEASUREMENT1_FEEDBACK != '' || measurement1_feedback != '' ? 'is-invalid' : '')" type="date" id="measurement1" name="measurement1"  v-model="measurement1">
                        <button 
                        type="button"
                        class="btn btn-primary bi bi-calendar-plus input-group-append"
                                   data-toggle="tooltip"
                        data-placement="top" title="+ 1 Monat"
                        @click="m1afterm0(1)">
                        </button>           
                        <div class="invalid-feedback">
                                    {{ measurement1_feedback }}
                            <br v-if="MEASUREMENT1_FEEDBACK != '' && measurement1_feedback != ''">
                            {{ MEASUREMENT1_FEEDBACK }}

                          </div>
                          </div>
                        </div>
                        <div class="row">
                        <label for="measurement2">Messung 2</label>
                        <div class="input-group" >
                        <input @blur="measurement2Blur()"   :class="'form-control ' + (MEASUREMENT2_FEEDBACK != '' || measurement2_feedback != '' ? 'is-invalid' : '')" type="date" id="measurement2" name="measurement2"  v-model="measurement2">
                        <button 
                        type="button"
                        class="btn btn-primary bi bi-calendar-plus input-group-append"
                                   data-toggle="tooltip"
                        data-placement="top" title="+ 1 Monat"
                        @click="m2afterm1(1)">
                        </button>
                                            <div class="invalid-feedback">
                                              {{ measurement2_feedback }}
                            <br v-if="MEASUREMENT2_FEEDBACK != '' && measurement2_feedback != ''">
                            {{ MEASUREMENT2_FEEDBACK }}
                          </div>
                        </div>
                        </div>
                        <div class="row">
                        <label for="measurement3">Messung 3</label>
                        <div class="input-group" >
                        <input @blur="measurement3Blur()"   :class="'form-control ' + (MEASUREMENT3_FEEDBACK != '' || measurement3_feedback != '' ? 'is-invalid' : '')" type="date" id="measurement3" name="measurement3"  v-model="measurement3">
                        <button 
                        type="button"
                        class="btn btn-primary bi bi-calendar-plus input-group-append"
                                   data-toggle="tooltip"
                        data-placement="top" title="+ 1 Monat"
                        @click="m3afterm2(1)">
                        </button>
                                           <div class="invalid-feedback">
                                            {{ measurement3_feedback }}
                            <br v-if="MEASUREMENT3_FEEDBACK != '' && measurement3_feedback != ''">
                            {{ MEASUREMENT3_FEEDBACK }}
                          </div>
                        </div>
                        </div>
                        <div class="row">
                        <label for="measurement4">Messung 4</label>
                        <div class="input-group" >
                        <input @blur="measurement4Blur()"   :class="'form-control ' + (MEASUREMENT4_FEEDBACK != ''|| measurement4_feedback != '' ? 'is-invalid' : '')" type="date" id="measurement4" name="measurement4" v-model="measurement4">
                        <button 
                        type="button"
                        class="btn btn-primary bi bi-calendar-plus input-group-append"
                                   data-toggle="tooltip"
                        data-placement="top" title="+ 1 Monat"
                        @click="m4afterm3(1)">
                        </button>
                                      <div class="invalid-feedback">
                                        {{ measurement4_feedback }}
                            <br v-if="MEASUREMENT4_FEEDBACK != '' && measurement4_feedback != ''">
                            {{ MEASUREMENT4_FEEDBACK }}
                          </div>
                        </div>
                        </div>
                        <div class="row">
                        <label for="measurement5">Messung 5</label>
                        <div class="input-group" >
                        <input @blur="measurement5Blur()"   :class="'form-control ' + (MEASUREMENT5_FEEDBACK != ''|| measurement5_feedback != '' ? 'is-invalid' : '')" type="date" id="measurement5" name="measurement5"  v-model="measurement5">
                        <button 
                        type="button"
                        class="btn btn-primary bi bi-calendar-plus input-group-append"
                                   data-toggle="tooltip"
                        data-placement="top" title="+ 1 Monat"
                        @click="m5afterm4(1)">
                        </button>
                                     <div class="invalid-feedback">
                                      {{ measurement5_feedback }}
                            <br v-if="MEASUREMENT5_FEEDBACK != '' && measurement5_feedback != ''">
                            {{ MEASUREMENT5_FEEDBACK }}
                          </div>
                        </div>
                        </div>
                        <div class="row">
                        <label for="measurement6">Messung 6</label>
                        <div class="input-group" >
                        <input @blur="measurement6Blur()"   :class="'form-control ' + (MEASUREMENT6_FEEDBACK != ''|| measurement6_feedback != '' ? 'is-invalid' : '')" type="date" id="measurement6" name="measurement6"  v-model="measurement6">
                        <button 
                        type="button"
                        class="btn btn-primary bi bi-calendar-plus input-group-append"
                                   data-toggle="tooltip"
                        data-placement="top" title="+ 1 Monat"
                        @click="m6afterm5(1)">
                        </button>
                                    <div class="invalid-feedback">
                                      {{ measurement6_feedback }}
                            <br v-if="MEASUREMENT6_FEEDBACK != '' && measurement6_feedback != ''">
                            {{ MEASUREMENT6_FEEDBACK }}
                          </div>
                        </div>
                        </div>
                    </div>
                    <div class="modal-footer"><button class="btn btn-secondary" @click="close()">Schließen</button>
                        <button type="button" class="btn btn-primary" @click="save()">Änderungen speichern</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import * as dt from '@/core/mixins/date.js'
import Success from "@/core/components/Success";
import ErrorList from '@/core/components/ErrorList';

export default defineComponent({
    data() {
        return {
            user_type: JSON.parse(this.user.user_type).enum,
            therapy_arm: JSON.parse(this.user.therapy_arm).enum,
            email: this.user.email,
            study_number: this.user.study_number,
            title: this.user.title,
            last_name: this.user.last_name,
            first_name: this.user.first_name,
            sex: this.user.sex,
            key: 0,
            minDate: new Date().toISOString().split('T')[0],
            measurement0: this.user.measurement0 ? new Date(this.user.measurement0).toISOString().split('T')[0] : "",  
            measurement1: this.user.measurement1 ? new Date(this.user.measurement1).toISOString().split('T')[0] : "",  
            measurement2: this.user.measurement2 ? new Date(this.user.measurement2).toISOString().split('T')[0] : "",
            measurement3: this.user.measurement3 ? new Date(this.user.measurement3).toISOString().split('T')[0] : "",
            measurement4: this.user.measurement4 ? new Date(this.user.measurement4).toISOString().split('T')[0] : "",
            measurement5: this.user.measurement5 ? new Date(this.user.measurement5).toISOString().split('T')[0] : "",
            measurement6: this.user.measurement6 ? new Date(this.user.measurement6).toISOString().split('T')[0] : "",
            measurement_on_paper: this.user.measurement_on_paper,
            measurement0_feedback: "",
            measurement1_feedback: "",
            measurement2_feedback: "",
            measurement3_feedback: "",
            measurement4_feedback: "",
            measurement5_feedback: "",
            measurement6_feedback: "",
            dropped_out_at: this.user.dropped_out_at ? new Date(this.user.dropped_out_at).toISOString().split('T')[0] : "",
            region: this.user.fk_region
        }
    },
    props: {
        show: {type: Boolean, default: false},
        user: {type: Object, default: null},
    },
    components: {
      Success,
      ErrorList
    },

    computed:{
            ...mapGetters({ 
                    LAST_NAME_FEEDBACK: 'Admin/LAST_NAME_FEEDBACK',
                    FIRST_NAME_FEEDBACK: 'Admin/FIRST_NAME_FEEDBACK',
                    EMAIL_FEEDBACK: 'Admin/EMAIL_FEEDBACK',
                    TITLE_FEEDBACK: 'Admin/TITLE_FEEDBACK',
                    STUDY_NUMBER_FEEDBACK: 'Admin/STUDY_NUMBER_FEEDBACK',
                    MEASUREMENT0_FEEDBACK: 'Admin/MEASUREMENT0_FEEDBACK',
                    MEASUREMENT1_FEEDBACK: 'Admin/MEASUREMENT1_FEEDBACK',
                    MEASUREMENT2_FEEDBACK: 'Admin/MEASUREMENT2_FEEDBACK',
                    MEASUREMENT3_FEEDBACK: 'Admin/MEASUREMENT3_FEEDBACK',
                    MEASUREMENT4_FEEDBACK: 'Admin/MEASUREMENT4_FEEDBACK',
                    MEASUREMENT5_FEEDBACK: 'Admin/MEASUREMENT5_FEEDBACK',
                    MEASUREMENT6_FEEDBACK: 'Admin/MEASUREMENT6_FEEDBACK',
                    DROPPED_OUT_AT_FEEDBACK: 'Admin/DROPPED_OUT_AT_FEEDBACK',
                    SHOW_SUCCESS: 'Admin/SHOW_SUCCESS',
                    ERRORS: 'Admin/EDIT_ERROR',
                      REGIONS: 'Admin/REGIONS',
                    REGION: 'Admin/REGION',
                    SEX: 'Admin/SEX'
                    }),
        user_name() {
            return this.user ? this.user.user_name : null
        },
        user_type_is_0(){
            return this.user_type == 0
        },
                user_type_is_1(){
            return this.user_type == 1
        },
                user_type_is_2(){
            return this.user_type == 2
        },
        therapy_arm_is_0(){
            return this.therapy_arm == 0
        },
           therapy_arm_is_1(){
            return this.therapy_arm == 1
        },
           therapy_arm_is_2(){
            return this.therapy_arm == 2
        },
    },

    methods: {
        save(){
          if(this.user_type_is_0){
            this.$store.dispatch("Admin/EDIT_USER_WITH_EMAIL", {measurement_on_paper: this.measurement_on_paper, dropped_out_at: this.dropped_out_at, study_number: this.study_number, title: this.title, user_name: this.user.user_name, first_name: this.first_name, last_name: this.last_name, sex: this.sex, email: this.email, user_type: this.user_type, therapy_arm: this.therapy_arm,  measurement0: this.measurement0, measurement1: this.measurement1, measurement2: this.measurement2, measurement3: this.measurement3, measurement4: this.measurement4, measurement5: this.measurement5, measurement6: this.measurement6, region: this.region})
          }else{
            this.$store.dispatch("Admin/EDIT_USER_WITH_EMAIL", {measurement_on_paper: this.measurement_on_paper, dropped_out_at: this.dropped_out_at, study_number: this.study_number, title: this.title, user_name: this.user.user_name, first_name: this.first_name, last_name: this.last_name, sex: this.sex, email: this.email, user_type: this.user_type, therapy_arm: this.therapy_arm,  measurement0: this.measurement0, measurement1: null, measurement2: null, measurement3: null, measurement4: null, measurement5: null, measurement6: null, region: this.region})
          }
        },
        close(){
            this.$emit("modalClosed")
        },
        userTypeChanged(event){
            this.user_type = parseInt(event.target.id)
        },
        therapyArmChanged(event){
            this.therapy_arm = parseInt(event.target.id)
        },
        clearDroppedOut(){
          this.dropped_out_at = ""
        },
        m1afterm0(value){
      this.measurement1 = dt.addMonths(this.measurement0, value)
    },
    m2afterm1(value){
      this.measurement2 = dt.addMonths(this.measurement1, value)
    },
    m3afterm2(value){
      this.measurement3 = dt.addMonths(this.measurement2, value)
    },
    m4afterm3(value){
      this.measurement4 = dt.addMonths(this.measurement3, value)
    },
    m5afterm4(value){
      this.measurement5 = dt.addMonths(this.measurement4, value)
    },
    m6afterm5(value){
      this.measurement6 = dt.addMonths(this.measurement5, value)
    },
    measurement0Blur(){
      var d = new Date(this.measurement0)
      if(d instanceof Date && !isNaN(d)){
        this.measurement0_feedback = ""
      }else{
        this.measurement0_feedback = "Ungültiges Datum"
      }
    },
    measurement1Blur(){
      var d = new Date(this.measurement1)
      if(d instanceof Date && !isNaN(d)){
        this.measurement1_feedback = ""
      }else{
        this.measurement1_feedback = "Ungültiges Datum"
      }
    },
    measurement2Blur(){
      var d = new Date(this.measurement2)
      if(d instanceof Date && !isNaN(d)){
        this.measurement2_feedback = ""
      }else{
        this.measurement2_feedback = "Ungültiges Datum"
      }
    },
    measurement3Blur(){
      var d = new Date(this.measurement3)
      if(d instanceof Date && !isNaN(d)){
        this.measurement3_feedback = ""
      }else{
        this.measurement3_feedback = "Ungültiges Datum"
      }
    },
        measurement4Blur(){
      var d = new Date(this.measurement4)
      if(d instanceof Date && !isNaN(d)){
        this.measurement4_feedback = ""
      }else{
        this.measurement4_feedback = "Ungültiges Datum"
      }
    },
        measurement5Blur(){
      var d = new Date(this.measurement5)
      if(d instanceof Date && !isNaN(d)){
        this.measurement5_feedback = ""
      }else{
        this.measurement5_feedback = "Ungültiges Datum"
      }
    },
        measurement6Blur(){
      var d = new Date(this.measurement6)
      if(d instanceof Date && !isNaN(d)){
        this.measurement6_feedback = ""
      }else{
        this.measurement6_feedback = "Ungültiges Datum"
      }
    },
        updateRegion(region){
          this.region = region
        },
        updateSex(sex){
          this.sex = sex
        }
    },

    watch: {
        'show' : function(){
            this.user_type = parseInt(JSON.parse(this.user.user_type).enum),
            this.therapy_arm = parseInt(JSON.parse(this.user.therapy_arm).enum)
            this.last_name = this.user.last_name
            this.first_name = this.user.first_name
            this.sex = this.user.sex
            this.title = this.user.title
            this.email = this.user.email
            this.region = this.user.fk_region
            this.measurement_on_paper = this.user.measurement_on_paper,
            this.measurement0 = this.user.measurement0 ? new Date(this.user.measurement0).toISOString().split('T')[0] : ""
            this.measurement1 = this.user.measurement1 ? new Date(this.user.measurement1).toISOString().split('T')[0] : ""
            this.measurement2 = this.user.measurement2 ? new Date(this.user.measurement2).toISOString().split('T')[0] : ""
            this.measurement3 = this.user.measurement3 ? new Date(this.user.measurement3).toISOString().split('T')[0] : ""
            this.measurement4 = this.user.measurement4 ? new Date(this.user.measurement4).toISOString().split('T')[0] : ""
            this.measurement5 = this.user.measurement5 ? new Date(this.user.measurement5).toISOString().split('T')[0] : ""
            this.measurement6 = this.user.measurement6 ? new Date(this.user.measurement6).toISOString().split('T')[0] : ""
            this.key += 1
            this.dropped_out_at = this.user.dropped_out_at
            this.$store.commit("Admin/SET_EDIT_SUCCESS", false)
          this.$store.commit("Admin/SET_EDIT_ERROR", [])
        }
    },
  mounted() {
      this.$store.dispatch("Admin/LIST_REGIONS")
  }
})
</script>
