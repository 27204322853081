import Login from '../components/Login'
import WelcomeMain from '@/modules/measurements/WelcomeMain'
import ChangePassword from '@/modules/users/components/ChangePassword'
import SetPassword from '@/modules/users/components/SetPassword'
import PasswordHelper from '@/modules/users/components/PasswordHelper'
import PasswordRecovery from '@/modules/users/components/PasswordRecovery'
import Kontakt from '@/modules/users/components/Kontakt'
import Kontakt2 from '@/modules/users/components/Kontakt2'
import Therapists from '@/modules/users/components/Therapists'
import Messages from '@/modules/users/components/Messages'
import Testuser from '@/modules/users/components/Testuser'

export default [
  {
    path: "/:catchAll(.*)",
    meta: { layout: 'no-bars' },
    component: Login
  },
  {
    path: "/login",
    name: 'Login',
    meta: { layout: 'no-bars' },
    component: Login
  },
  {
    path: "/",
    name: 'Home',
    meta: { requiresAuth: true },
    component: WelcomeMain
  },
  {
    path: "/users/change_password",
    name: 'ChangePassword',
    meta: { requiresAuth: true },
    component: ChangePassword
  },
  {
    path: "/users/therapists",
    name: 'Therapists2',
    meta: { requiresAuth: true },
    component: Therapists
  },
  {
    path: "/users/messages",
    name: 'Messages',
    meta: { requiresAuth: true },
    component: Messages
  },
  {
    path: "/users/set_password/:token",
    name: 'SetPassword',
    meta: { requiresAuth: false, layout: 'no-bars' },
    component: SetPassword
  },
  {
    path: "/users/password_helper",
    name: 'PasswordHelper',
    meta: { requiresAuth: false, layout: 'no-bars' },
    component: PasswordHelper
  },
  {
    path: "/users/password_recovery/:user_token",
    name: 'PasswordRecovery',
    meta: { requiresAuth: false, layout: 'no-bars' },
    component: PasswordRecovery
  },
  {
    path: "/users/contact",
    name: 'Kontakt',
    meta: { requiresAuth: true },
    component: Kontakt
  },
  {
    path: "/contact",
    name: 'Kontakt2',
    meta: { requiresAuth: false, layout: 'no-bars' },
    component: Kontakt2
  },
  {
    path: "/testbenutzer",
    name: 'Testuser',
    meta: { requiresAuth: false, layout: 'no-bars' },
    component: Testuser
  },
]
