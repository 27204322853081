import axios from 'axios'
import router from '@/router'
import store from '@/store'

// const API_URL = process.env.VUE_APP_ROOT_API || 'http://localhost:8765/'
const API_URL = (process.env.NODE_ENV === 'development') ? 'http://localhost:8765' : 'https://lepalumo.tumorzentrum-regensburg.de'

const instance = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
})

instance.defaults.params = {}
//instance.defaults.params[',DanaInfo'] = '192.168.250.1'
instance.defaults.params['version'] = '1.8.2'

instance.interceptors.request.use(
  config => {
    const token = localStorage.fk_session
    if( token ) config.headers.Authorization = 'Bearer ' + token
    return config
  }
)

/*************************************************************************
 * hier werden alle 401-Fehler aufgefangen, die von Rails im
 * ApplicationController in der require_login! - Action gerendert werden.
 *************************************************************************/
instance.interceptors.response.use(
  response => {
    return response
  },
  error => {
    switch(error.response.status) {
      case 401: {
        console.log('Not Authenticated!')
        store.commit('User/SET_ERRORS', ['Ihre Sitzung ist abgelaufen. Bitte loggen Sie sich neu ein.'])
        router.push({name: 'Login'})
        break;
      }
      case 404: {
        console.log('Not Found!')
        break;
      }
      case 426: {
        console.log('Upgrade required!')
        store.commit('User/SET_ERRORS', ['Die App-Version ist nicht aktuell. Bitte leeren Sie den Browser-Cache, um die aktuelle App-Version nutzen zu können.'])
        router.push({name: 'Login'})
        break;
      }
    }
    return Promise.reject(error)
  }
)

export default instance
